// src/components/dashboard/AnalystRatings.js
import React, { useMemo } from 'react';
import { Paper, Typography, Box, LinearProgress, Stack } from '@mui/material';

const RatingBar = ({ label, value, color, percentage }) => (
  <Box sx={{ mb: 2, width: '100%' }}>
    <Stack direction="row" spacing={2} alignItems="center">
      <Box sx={{ width: '25%' }}>
        <Typography variant="body2">{label}</Typography>
      </Box>
      <Box sx={{ width: '60%' }}>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: 20,
            borderRadius: 1,
            backgroundColor: 'grey.200',
            '& .MuiLinearProgress-bar': { backgroundColor: color }
          }}
        />
      </Box>
      <Box sx={{ width: '15%' }}>
        <Typography variant="body2" align="right">
          {value} ({percentage.toFixed(1)}%)
        </Typography>
      </Box>
    </Stack>
  </Box>
);

const AnalystRatings = ({ stock }) => {
  const ratings = useMemo(() => {
    const values = {
      strongBuy: parseInt(stock.overview.AnalystRatingStrongBuy) || 0,
      buy: parseInt(stock.overview.AnalystRatingBuy) || 0,
      hold: parseInt(stock.overview.AnalystRatingHold) || 0,
      sell: parseInt(stock.overview.AnalystRatingSell) || 0,
      strongSell: parseInt(stock.overview.AnalystRatingStrongSell) || 0
    };

    const total = Object.values(values).reduce((sum, val) => sum + val, 0);

    return {
      ...values,
      total,
      percentages: {
        strongBuy: (values.strongBuy / total) * 100,
        buy: (values.buy / total) * 100,
        hold: (values.hold / total) * 100,
        sell: (values.sell / total) * 100,
        strongSell: (values.strongSell / total) * 100
      }
    };
  }, [stock.overview]);

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Analyst Recommendations
      </Typography>

      <Stack spacing={2}>
        <RatingBar
          label="Strong Buy"
          value={ratings.strongBuy}
          percentage={ratings.percentages.strongBuy}
          color="#2E7D32"
        />
        <RatingBar
          label="Buy"
          value={ratings.buy}
          percentage={ratings.percentages.buy}
          color="#4CAF50"
        />
        <RatingBar
          label="Hold"
          value={ratings.hold}
          percentage={ratings.percentages.hold}
          color="#FFC107"
        />
        <RatingBar
          label="Sell"
          value={ratings.sell}
          percentage={ratings.percentages.sell}
          color="#FF9800"
        />
        <RatingBar
          label="Strong Sell"
          value={ratings.strongSell}
          percentage={ratings.percentages.strongSell}
          color="#F44336"
        />
      </Stack>

      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Based on {ratings.total} analyst ratings
      </Typography>
    </Paper>
  );
};

export default AnalystRatings;
