// src/components/Compare.js
import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  CssBaseline,
  useMediaQuery,
  useTheme,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  AccountCircle,
  Notifications,
  Menu as MenuIcon,
  Search,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../firebaseConfig";
import { useAuth } from "../contexts/AuthContext";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import db from "../firebaseConfig";
import { useEffect } from "react";
import { useTheme as useCustomTheme } from "../contexts/ThemeContext";

const SearchBar = ({ onStockSelect }) => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { themeMode } = useCustomTheme();

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const stocksRef = collection(db, "stockList");
        const stocksSnapshot = await getDocs(stocksRef);
        const stocksData = stocksSnapshot.docs.map((doc) => doc.data());
        const stocksList = Object.keys(stocksData[0].tickers).map((ticker) => ({
          ticker,
          name: stocksData[0].tickers[ticker].name,
        }));
        setStocks(stocksList);
      } catch (error) {
        console.error("Error fetching stocks:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStocks();
  }, []);

  const handleStockSelect = async (ticker) => {
    if (!ticker) return;
    setLoading(true);
    try {
      const stockDoc = await getDoc(doc(db, "stockDetails", ticker));
      if (stockDoc.exists()) {
        onStockSelect(stockDoc.data());
      }
    } catch (error) {
      console.error("Error fetching stock data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      options={stocks}
      getOptionLabel={(option) => `${option.ticker} - ${option.name}`}
      loading={loading}
      onChange={(event, newValue) => {
        if (newValue) {
          handleStockSelect(newValue.ticker);
        }
      }}
      filterOptions={(options, { inputValue }) => {
        const input = inputValue.toLowerCase();
        // First find exact ticker matches
        const exactTickerMatches = options.filter(
          (option) => option.ticker.toLowerCase() === input
        );
        // Then find partial ticker matches
        const partialTickerMatches = options.filter(
          (option) =>
            option.ticker.toLowerCase().includes(input) &&
            option.ticker.toLowerCase() !== input
        );
        // Finally find name matches
        const nameMatches = options.filter(
          (option) =>
            option.name.toLowerCase().includes(input) &&
            !option.ticker.toLowerCase().includes(input)
        );

        return [...exactTickerMatches, ...partialTickerMatches, ...nameMatches];
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for companies..."
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Search
                color="action"
                sx={{
                  ml: 1,
                  mr: -0.5,
                  color:
                    themeMode === "light" ? "action.active" : "text.secondary",
                }}
              />
            ),
          }}
          sx={{
            width: "100%",
            maxWidth: "600px",
            backgroundColor:
              themeMode === "light" ? "background.paper" : "background.default",
            "& .MuiOutlinedInput-root": {
              height: "40px",
              borderRadius: 2,
              "&:hover fieldset": {
                borderColor: "primary.main",
              },
              "& fieldset": {
                borderColor:
                  themeMode === "light" ? "divider" : "action.selected",
              },
              "& input": {
                color: "text.primary",
              },
            },
          }}
        />
      )}
    />
  );
};

const Compare = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => logout(navigate);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
          color: "text.primary",
        }}
        elevation={0}
      >
        <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }}>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <img
            src={process.env.PUBLIC_URL + "/logo192.png"}
            alt="Logo"
            style={{
              height: isMobile ? "24px" : "32px",
              width: "auto",
              marginRight: isMobile ? "8px" : "16px",
            }}
          />

          <Box sx={{ flexGrow: 1 }}>
            {/* <SearchBar onStockSelect={setSelectedStock} /> */}
          </Box>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", gap: 2 }}
          >
            <Button
              component={Link}
              to="/dashboard"
              color="inherit"
              sx={{
                textTransform: "none",
                fontWeight: (theme) =>
                  window.location.pathname === "/dashboard" ? 600 : 400,
                "&:hover": { backgroundColor: "action.hover" },
              }}
            >
              Dashboard
            </Button>

            <Button
              component={Link}
              to="/compare"
              color="inherit"
              sx={{
                textTransform: "none",
                fontWeight: (theme) =>
                  window.location.pathname === "/compare" ? 600 : 400,
                "&:hover": { backgroundColor: "action.hover" },
              }}
            >
              Compare
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!isMobile && (
              <>
                <IconButton color="inherit">
                  <Notifications />
                </IconButton>
                <IconButton color="inherit" onClick={handleMenu}>
                  <AccountCircle />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ flexGrow: 1, mt: { xs: 7, sm: 8 } }}>
        {/* Add Compare page content here */}
      </Box>
    </Box>
  );
};

export default Compare;
