import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#424242",
      dark: "#212121",
      light: "#757575",
    },
    secondary: {
      main: "#D32F2F",
      dark: "#B71C1C",
      light: "#EF5350",
    },
    background: {
      default: "#ffffff",
      paper: "#fafafa",
    },
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#757575",
      dark: "#424242",
      light: "#9E9E9E",
    },
    secondary: {
      main: "#EF5350",
      dark: "#D32F2F",
      light: "#FF8A80",
    },
    background: {
      default: "#303030",
      paper: "#424242",
    }
  }
});

export const oledTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#757575",
      dark: "#424242",
      light: "#9E9E9E",
    },
    secondary: {
      main: "#EF5350",
      dark: "#D32F2F",
      light: "#FF8A80",
    },
    background: {
      default: "#000000",
      paper: "#121212",
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
