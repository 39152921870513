// src/hooks/useHighchartsTheme.js
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import Highcharts from 'highcharts';

export const useHighchartsTheme = () => {
  const theme = useTheme();

  useEffect(() => {
    const chartTheme = {
      colors: [theme.palette.primary.main, theme.palette.secondary.main],
      chart: {
        backgroundColor: theme.palette.background.paper,
        style: {
          fontFamily: theme.typography.fontFamily
        }
      },
      title: {
        style: {
          color: theme.palette.text.primary
        }
      },
      subtitle: {
        style: {
          color: theme.palette.text.secondary
        }
      },
      xAxis: {
        gridLineColor: alpha(theme.palette.divider, 0.05),
        labels: {
          style: {
            color: theme.palette.text.secondary
          }
        },
        lineColor: theme.palette.divider,
        tickColor: theme.palette.divider,
      },
      yAxis: {
        gridLineColor: alpha(theme.palette.divider, 0.05),
        labels: {
          style: {
            color: theme.palette.text.secondary
          }
        },
        lineColor: theme.palette.divider,
        tickColor: theme.palette.divider,
      },
      tooltip: {
        backgroundColor: alpha(theme.palette.background.paper, 0.9),
        style: {
          color: theme.palette.text.primary
        }
      },
      legend: {
        itemStyle: {
          color: theme.palette.text.primary
        },
        itemHoverStyle: {
          color: theme.palette.text.secondary
        }
      }
    };

    Highcharts.setOptions(chartTheme);

    // Force redraw of all charts
    requestAnimationFrame(() => {
      Highcharts.charts.forEach(chart => {
        if (chart) {
          chart.update(chartTheme, true, true);
        }
      });
    });

    return () => {
      Highcharts.setOptions({}); // Reset on unmount
    };
  }, [theme]);
};
