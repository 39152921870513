// src/components/dashboard/Overview.js
import React from 'react';
import { Paper, Typography, Box, Chip } from '@mui/material';

const Overview = ({ stock }) => (
  <Paper sx={{ p: 3, mb: 3 }}>
    <Typography variant="h4">
      {stock.overview.Name} ({stock.overview.Symbol})
    </Typography>
    {stock.overview.Description && stock.overview.Description !== "None" && (
      <Typography color="textSecondary">{stock.overview.Description}</Typography>
    )}
    <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
      <Chip label={`Sector: ${stock.overview.Sector}`} />
      <Chip label={`Industry: ${stock.overview.Industry}`} />
    </Box>
  </Paper>
);

export default Overview;
