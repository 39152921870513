// src/components/dashboard/ChartThemeWrapper.js
import React from 'react';
import { useHighchartsTheme } from '../../hooks/useHighchartsTheme';

const ChartThemeWrapper = ({ children }) => {
  useHighchartsTheme();
  return children;
};

export default ChartThemeWrapper;
