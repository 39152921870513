// src/components/dashboard/KeyMetrics.js
import React from 'react';
import { Paper, Typography, Box, Divider, useTheme, useMediaQuery } from '@mui/material';
import { formatPercentage, formatLargeValue } from '../../constants/numberUtils';

const MetricItem = ({ label, value }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 0.75,
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.05)'
            : 'rgba(0, 0, 0, 0.02)',
          borderRadius: 1,
        }
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontSize: { xs: '0.75rem', sm: '0.875rem' },
          fontWeight: 400
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          fontSize: { xs: '0.75rem', sm: '0.875rem' },
          color: 'text.primary'
        }}
      >
        {value || 'N/A'}
      </Typography>
    </Box>
  );
};

const MetricColumn = ({ title, metrics, isLast }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        minWidth: { xs: '100%', sm: '200px' },
        mb: { xs: 3, sm: 0 },
        px: { xs: 2, sm: 2 },
        borderRight: {
          xs: 'none',
          sm: isLast ? 'none' : `1px solid ${theme.palette.divider}`
        }
      }}
    >
      <Typography
        variant="subtitle1"
        color="primary"
        sx={{
          mb: 1,
          fontWeight: 600,
          fontSize: { xs: '0.9rem', sm: '1rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.1px'
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2 }}>
        {metrics.map((metric, index) => (
          <MetricItem key={index} {...metric} />
        ))}
      </Box>
      {isMobile && <Divider sx={{ mt: 3 }} />}
    </Box>
  );
};

const KeyMetrics = ({ stock }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatMetric = (value, type) => {
    if (!value) return 'N/A';
    switch (type) {
      case 'percentage':
        return formatPercentage(value);
      case 'currency':
        return formatLargeValue(value);
      default:
        return value;
    }
  };

  const metricColumns = [
    {
      title: 'Market Performance',
      metrics: [
        { label: '52 Week High', value: formatMetric(stock.overview['52WeekHigh'], 'currency') },
        { label: '52 Week Low', value: formatMetric(stock.overview['52WeekLow'], 'currency') },
        { label: '50 Day MA', value: formatMetric(stock.overview['50DayMovingAverage'], 'currency') },
        { label: '200 Day MA', value: formatMetric(stock.overview['200DayMovingAverage'], 'currency') },
        { label: 'Beta', value: formatMetric(stock.overview.Beta) },
        { label: 'Shares Outstanding', value: formatMetric(stock.overview.SharesOutstanding, 'currency') }
      ]
    },
    {
      title: 'Valuation Metrics',
      metrics: [
        { label: 'Market Cap', value: formatMetric(stock.overview.MarketCapitalization, 'currency') },
        { label: 'PE Ratio', value: formatMetric(stock.overview.PERatio) },
        { label: 'Forward PE', value: formatMetric(stock.overview.ForwardPE) },
        { label: 'Trailing PE', value: formatMetric(stock.overview.TrailingPE) },
        { label: 'PEG Ratio', value: formatMetric(stock.overview.PEGRatio) },
        { label: 'Price/Book', value: formatMetric(stock.overview.PriceToBookRatio) },
        { label: 'Price/Sales', value: formatMetric(stock.overview.PriceToSalesRatioTTM) },
        { label: 'EV/EBITDA', value: formatMetric(stock.overview.EVToEBITDA) },
        { label: 'EV/Revenue', value: formatMetric(stock.overview.EVToRevenue) }
      ]
    },
    {
      title: 'Financial Performance',
      metrics: [
        { label: 'Revenue', value: formatMetric(stock.overview.RevenueTTM, 'currency') },
        { label: 'EBITDA', value: formatMetric(stock.overview.EBITDA, 'currency') },
        { label: 'Gross Profit', value: formatMetric(stock.overview.GrossProfitTTM, 'currency') },
        { label: 'Operating Margin', value: formatMetric(stock.overview.OperatingMarginTTM, 'percentage') },
        { label: 'Profit Margin', value: formatMetric(stock.overview.ProfitMargin, 'percentage') },
        { label: 'ROE', value: formatMetric(stock.overview.ReturnOnEquityTTM, 'percentage') },
        { label: 'ROA', value: formatMetric(stock.overview.ReturnOnAssetsTTM, 'percentage') }
      ]
    },
    {
      title: 'Growth & Dividends',
      metrics: [
        { label: 'Earnings Growth (YoY)', value: formatMetric(stock.overview.QuarterlyEarningsGrowthYOY, 'percentage') },
        { label: 'Revenue Growth (YoY)', value: formatMetric(stock.overview.QuarterlyRevenueGrowthYOY, 'percentage') },
        { label: 'EPS', value: formatMetric(stock.overview.EPS, 'currency') },
        { label: 'Diluted EPS', value: formatMetric(stock.overview.DilutedEPSTTM, 'currency') },
        { label: 'Dividend Yield', value: formatMetric(stock.overview.DividendYield, 'percentage') },
        { label: 'Dividend/Share', value: formatMetric(stock.overview.DividendPerShare, 'currency') }
      ]
    },
    // {
    //   title: 'Company Info',
    //   metrics: [
    //     { label: 'Sector', value: stock.overview.Sector },
    //     { label: 'Industry', value: stock.overview.Industry },
    //     { label: 'Country', value: stock.overview.Country },
    //     { label: 'Exchange', value: stock.overview.Exchange },
    //     { label: 'Currency', value: stock.overview.Currency },
    //     { label: 'Fiscal Year End', value: stock.overview.FiscalYearEnd }
    //   ]
    // },
    // {
    //   title: 'Analyst Coverage',
    //   metrics: [
    //     { label: 'Strong Buy', value: stock.overview.AnalystRatingStrongBuy },
    //     { label: 'Buy', value: stock.overview.AnalystRatingBuy },
    //     { label: 'Hold', value: stock.overview.AnalystRatingHold },
    //     { label: 'Sell', value: stock.overview.AnalystRatingSell },
    //     { label: 'Strong Sell', value: stock.overview.AnalystRatingStrongSell }
    //   ]
    // }
  ];

  return (
    <Paper
      elevation={2}
      sx={{
        pt: { xs: 1, sm: 2 },
        pb: 2,
        mb: 2,
        width: '100%',
        backgroundColor: 'background.paper', // Use theme background color
        borderRadius: 2
      }}
    >
      {/* <Typography
        variant="h6"
        sx={{
          mb: 1,
          fontWeight: 600,
          fontSize: { xs: '1.1rem', sm: '1.25rem' }
        }}
      >
        Key Metrics
      </Typography> */}
      {/* <Divider sx={{ mb: { xs: 2, sm: 3 } }} /> */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)'
          },
          gap: { xs: 0, sm: 0 },
          '& > *:not(:last-child)': {
            borderBottom: {
              xs: `1px solid ${theme.palette.divider}`,
              sm: 'none'
            }
          }
        }}
      >
        {metricColumns.map((column, index) => (
          <MetricColumn
            key={column.title}
            {...column}
            isLast={index === metricColumns.length - 1}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default KeyMetrics;
