// src/components/Register.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Paper,
} from "@mui/material";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import db from "../firebaseConfig";
import Footer from "./Footer";
import { ArrowBack } from '@mui/icons-material';

const Register = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validation function
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Validate email first
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      setLoading(false);
      return;
    }

    try {
      // Add email to waitlist collection
      await addDoc(collection(db, "waitlist"), {
        email,
        timestamp: serverTimestamp(),
        status: "pending" // Could be used for managing invite status later
      });

      setSuccess(true);
      setEmail("");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (err) {
      setError("Failed to join waitlist. Please try again.");
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      background: "linear-gradient(135deg, #ece9e6, #ffffff)",
    }}>
      <Container component="main" maxWidth="xs">
        <Box sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <img
            src={process.env.PUBLIC_URL + "/logo192.png"}
            alt="Lightspeed Insights Logo"
            style={{ height: "48px", width: "auto" }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button
              color="primary"
              onClick={() => navigate('/')}
              startIcon={<ArrowBack />}
            >
              Back to Home
            </Button>
          </Box>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Join the Waitlist
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2, width: "100%" }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2, width: "100%" }}>
              Thanks for joining! We'll notify you when access is available.
            </Alert>
          )}
          <Paper elevation={3} sx={{ p: 4, width: "100%", borderRadius: 2 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading || !email}
              >
                {loading ? "Joining..." : "Join Waitlist"}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Register;
