// src/constants/utils.js
export const formatLargeNumber = (number) => {
    const abs = Math.abs(number);
    if (abs >= 1e9) {
      return { value: number / 1e9, suffix: 'B' };
    } else if (abs >= 1e6) {
      return { value: number / 1e6, suffix: 'M' };
    } else if (abs >= 1e3) {
      return { value: number / 1e3, suffix: 'K' };
    }
    return { value: number, suffix: '' };
  };
