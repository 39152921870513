// src/components/DemoStockDashboard.js
import React, { useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Paper,
  Container,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Fab,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  AccountCircle,
  AccountBalance,
  Assessment,
  ShowChart,
  Timeline,
  AttachMoney,
  Menu as MenuIcon,
  ArrowBack,
  KeyboardArrowUp,
  Notifications,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PriceChart from "./dashboard/PriceChart";
import BalanceSheetChart from "./dashboard/BalanceSheetChart";
import IncomeChart from "./dashboard/IncomeChart";
import CashFlowChart from "./dashboard/CashFlowChart";
import Overview from "./dashboard/Overview";
import KeyMetrics from "./dashboard/KeyMetrics";
import AnalystRatings from "./dashboard/AnalystRatings";
import DividendChart from "./dashboard/DividendChart";

// Mock stock data structure matching the API format
const mockStockData = {
  overview: {
    // Basic Info
    Symbol: "DEMO",
    CompanyName: "Demo Technologies Corp.",
    Description:
      "Demo Technologies Corp. is a leading provider of cloud computing solutions.",
    Sector: "Technology",
    Industry: "Software & Technology Services",
    Country: "United States",
    Exchange: "NASDAQ",
    Currency: "USD",
    FiscalYearEnd: "December",

    // Market Data
    MarketCapitalization: "85000000000",
    SharesOutstanding: "1000000000",
    "52WeekHigh": "95.50",
    "52WeekLow": "65.30",
    "50DayMovingAverage": "82.45",
    "200DayMovingAverage": "78.90",
    Beta: "1.15",

    // Valuation Metrics
    PERatio: "32.4",
    ForwardPE: "28.5",
    TrailingPE: "33.2",
    PEGRatio: "1.8",
    PriceToBookRatio: "4.2",
    PriceToSalesRatioTTM: "8.5",
    EVToEBITDA: "22.5",
    EVToRevenue: "7.8",

    // Financial Performance
    RevenueTTM: "108000000000",
    RevenuePerShareTTM: "108",
    EBITDA: "35000000000",
    GrossProfitTTM: "68900000000",
    OperatingMarginTTM: "0.28",
    ProfitMargin: "0.22",
    EPS: "2.65",
    DilutedEPSTTM: "2.60",

    // Growth Metrics
    QuarterlyEarningsGrowthYOY: "0.25",
    QuarterlyRevenueGrowthYOY: "0.18",

    // Returns
    ReturnOnAssetsTTM: "0.15",
    ReturnOnEquityTTM: "0.25",

    // Dividends
    DividendYield: "0.012",
    DividendPerShare: "1.40",
    PayoutRatio: "0.35",

    // Analyst Ratings
    AnalystRatingStrongBuy: "8",
    AnalystRatingBuy: "12",
    AnalystRatingHold: "5",
    AnalystRatingSell: "2",
    AnalystRatingStrongSell: "1",
  },

  time_series_monthly_adjusted: {
    "Monthly Adjusted Time Series": {
      "2024-02-01": { "5. adjusted close": "245.80", "6. volume": "8500000" },
      "2024-01-01": { "5. adjusted close": "238.45", "6. volume": "7800000" },
      "2023-12-01": { "5. adjusted close": "232.60", "6. volume": "8200000" },
      "2023-11-01": { "5. adjusted close": "225.30", "6. volume": "7500000" },
      "2023-10-01": { "5. adjusted close": "218.75", "6. volume": "7900000" },
      "2023-09-01": { "5. adjusted close": "210.40", "6. volume": "7200000" },
      "2023-08-01": { "5. adjusted close": "205.90", "6. volume": "6800000" },
      "2023-07-01": { "5. adjusted close": "198.25", "6. volume": "7100000" },
      "2023-06-01": { "5. adjusted close": "192.80", "6. volume": "6500000" },
      "2023-05-01": { "5. adjusted close": "188.45", "6. volume": "6900000" },
      "2023-04-01": { "5. adjusted close": "182.60", "6. volume": "6200000" },
      "2023-03-01": { "5. adjusted close": "175.90", "6. volume": "6600000" },
    },
  },
  balance_sheet: {
    quarterlyReports: [
      {
        fiscalDateEnding: "2023-12-31",
        totalAssets: "125000000000",
        totalLiabilities: "45000000000",
        totalShareholderEquity: "80000000000",
        cashAndEquivalents: "25000000000",
      },
      {
        fiscalDateEnding: "2023-09-30",
        totalAssets: "120000000000",
        totalLiabilities: "43000000000",
        totalShareholderEquity: "77000000000",
        cashAndEquivalents: "23000000000",
      },
      {
        fiscalDateEnding: "2023-06-30",
        totalAssets: "118000000000",
        totalLiabilities: "42000000000",
        totalShareholderEquity: "76000000000",
        cashAndEquivalents: "22000000000",
      },
      {
        fiscalDateEnding: "2023-03-31",
        totalAssets: "115000000000",
        totalLiabilities: "41000000000",
        totalShareholderEquity: "74000000000",
        cashAndEquivalents: "21000000000",
      },
      {
        fiscalDateEnding: "2022-12-31",
        totalAssets: "110000000000",
        totalLiabilities: "39000000000",
        totalShareholderEquity: "71000000000",
        cashAndEquivalents: "20000000000",
      },
    ],
    annualReports: [
      {
        fiscalDateEnding: "2023-12-31",
        totalAssets: "125000000000",
        totalCurrentAssets: "45000000000",
        cashAndCashEquivalentsAtCarryingValue: "25000000000",
        cashAndShortTermInvestments: "30000000000",
        inventory: "2000000000",
        currentNetReceivables: "10000000000",
        totalNonCurrentAssets: "80000000000",
        propertyPlantEquipment: "35000000000",
        goodwill: "20000000000",
        intangibleAssets: "15000000000",
        longTermInvestments: "8000000000",
        totalLiabilities: "45000000000",
        totalCurrentLiabilities: "20000000000",
        currentAccountsPayable: "8000000000",
        shortTermDebt: "5000000000",
        totalNonCurrentLiabilities: "25000000000",
        longTermDebt: "20000000000",
        totalShareholderEquity: "80000000000",
        retainedEarnings: "60000000000",
        commonStock: "20000000000",
        commonStockSharesOutstanding: "1000000000",
      },
      {
        fiscalDateEnding: "2022-12-31",
        totalAssets: "110000000000",
        totalCurrentAssets: "40000000000",
        cashAndCashEquivalentsAtCarryingValue: "22000000000",
        cashAndShortTermInvestments: "26000000000",
        inventory: "1800000000",
        currentNetReceivables: "8500000000",
        totalNonCurrentAssets: "70000000000",
        propertyPlantEquipment: "30000000000",
        goodwill: "18000000000",
        intangibleAssets: "13000000000",
        longTermInvestments: "7000000000",
        totalLiabilities: "39000000000",
        totalCurrentLiabilities: "17000000000",
        currentAccountsPayable: "7000000000",
        shortTermDebt: "4000000000",
        totalNonCurrentLiabilities: "22000000000",
        longTermDebt: "17000000000",
        totalShareholderEquity: "71000000000",
        retainedEarnings: "51000000000",
        commonStock: "20000000000",
        commonStockSharesOutstanding: "980000000",
      },
      {
        fiscalDateEnding: "2021-12-31",
        totalAssets: "95000000000",
        totalCurrentAssets: "35000000000",
        cashAndCashEquivalentsAtCarryingValue: "19000000000",
        cashAndShortTermInvestments: "22000000000",
        inventory: "1500000000",
        currentNetReceivables: "7000000000",
        totalNonCurrentAssets: "60000000000",
        propertyPlantEquipment: "25000000000",
        goodwill: "16000000000",
        intangibleAssets: "11000000000",
        longTermInvestments: "6000000000",
        totalLiabilities: "34000000000",
        totalCurrentLiabilities: "15000000000",
        currentAccountsPayable: "6000000000",
        shortTermDebt: "3500000000",
        totalNonCurrentLiabilities: "19000000000",
        longTermDebt: "15000000000",
        totalShareholderEquity: "61000000000",
        retainedEarnings: "41000000000",
        commonStock: "20000000000",
        commonStockSharesOutstanding: "950000000",
      },
      {
        fiscalDateEnding: "2020-12-31",
        totalAssets: "82000000000",
        totalCurrentAssets: "30000000000",
        cashAndCashEquivalentsAtCarryingValue: "17000000000",
        cashAndShortTermInvestments: "19000000000",
        inventory: "1300000000",
        currentNetReceivables: "6000000000",
        totalNonCurrentAssets: "52000000000",
        propertyPlantEquipment: "22000000000",
        goodwill: "14000000000",
        intangibleAssets: "9000000000",
        longTermInvestments: "5000000000",
        totalLiabilities: "30000000000",
        totalCurrentLiabilities: "13000000000",
        currentAccountsPayable: "5000000000",
        shortTermDebt: "3000000000",
        totalNonCurrentLiabilities: "17000000000",
        longTermDebt: "13000000000",
        totalShareholderEquity: "52000000000",
        retainedEarnings: "32000000000",
        commonStock: "20000000000",
        commonStockSharesOutstanding: "920000000",
      },
      {
        fiscalDateEnding: "2019-12-31",
        totalAssets: "70000000000",
        totalCurrentAssets: "25000000000",
        cashAndCashEquivalentsAtCarryingValue: "15000000000",
        cashAndShortTermInvestments: "16000000000",
        inventory: "1100000000",
        currentNetReceivables: "5000000000",
        totalNonCurrentAssets: "45000000000",
        propertyPlantEquipment: "19000000000",
        goodwill: "12000000000",
        intangibleAssets: "8000000000",
        longTermInvestments: "4000000000",
        totalLiabilities: "26000000000",
        totalCurrentLiabilities: "11000000000",
        currentAccountsPayable: "4000000000",
        shortTermDebt: "2500000000",
        totalNonCurrentLiabilities: "15000000000",
        longTermDebt: "11000000000",
        totalShareholderEquity: "44000000000",
        retainedEarnings: "24000000000",
        commonStock: "20000000000",
        commonStockSharesOutstanding: "900000000",
      },
    ],
  },
  income_statement: {
    annualReports: [
      {
        fiscalDateEnding: "2023-12-31",
        totalRevenue: "108000000000",
        costOfRevenue: "39100000000",
        grossProfit: "68900000000",
        operatingExpenses: "38000000000",
        researchAndDevelopment: "15000000000",
        sellingGeneralAndAdministrative: "20000000000",
        operatingIncome: "30900000000",
        interestExpense: "1000000000",
        ebitda: "35000000000",
        ebit: "30900000000",
        incomeBeforeTax: "29900000000",
        incomeTaxExpense: "4500000000",
        netIncome: "25400000000",
      },
      {
        fiscalDateEnding: "2022-12-31",
        totalRevenue: "92000000000",
        costOfRevenue: "33500000000",
        grossProfit: "58500000000",
        operatingExpenses: "31500000000",
        researchAndDevelopment: "13000000000",
        sellingGeneralAndAdministrative: "16500000000",
        operatingIncome: "27000000000",
        interestExpense: "850000000",
        ebitda: "30000000000",
        ebit: "27000000000",
        incomeBeforeTax: "26150000000",
        incomeTaxExpense: "3900000000",
        netIncome: "22250000000",
      },
      {
        fiscalDateEnding: "2021-12-31",
        totalRevenue: "78000000000",
        costOfRevenue: "28600000000",
        grossProfit: "49400000000",
        operatingExpenses: "26600000000",
        researchAndDevelopment: "11000000000",
        sellingGeneralAndAdministrative: "14000000000",
        operatingIncome: "22800000000",
        interestExpense: "750000000",
        ebitda: "25500000000",
        ebit: "22800000000",
        incomeBeforeTax: "22050000000",
        incomeTaxExpense: "3300000000",
        netIncome: "18750000000",
      },
      {
        fiscalDateEnding: "2020-12-31",
        totalRevenue: "65000000000",
        costOfRevenue: "24000000000",
        grossProfit: "41000000000",
        operatingExpenses: "22000000000",
        researchAndDevelopment: "9000000000",
        sellingGeneralAndAdministrative: "11500000000",
        operatingIncome: "19000000000",
        interestExpense: "650000000",
        ebitda: "21500000000",
        ebit: "19000000000",
        incomeBeforeTax: "18350000000",
        incomeTaxExpense: "2750000000",
        netIncome: "15600000000",
      },
      {
        fiscalDateEnding: "2019-12-31",
        totalRevenue: "54000000000",
        costOfRevenue: "20000000000",
        grossProfit: "34000000000",
        operatingExpenses: "18200000000",
        researchAndDevelopment: "7500000000",
        sellingGeneralAndAdministrative: "9500000000",
        operatingIncome: "15800000000",
        interestExpense: "550000000",
        ebitda: "18000000000",
        ebit: "15800000000",
        incomeBeforeTax: "15250000000",
        incomeTaxExpense: "2300000000",
        netIncome: "12950000000",
      },
    ],
  },
  cash_flow: {
    annualReports: [
      {
        fiscalDateEnding: "2023-12-31",
        operatingCashflow: "47500000000",
        capitalExpenditures: "-11900000000",
        cashflowFromInvestment: "-15000000000",
        cashflowFromFinancing: "-20000000000",
        dividendPayoutCommonStock: "-5600000000",
        paymentsForRepurchaseOfCommonStock: "-12000000000",
        changeInCashAndCashEquivalents: "12500000000",
      },
      {
        fiscalDateEnding: "2022-12-31",
        operatingCashflow: "40200000000",
        capitalExpenditures: "-10100000000",
        cashflowFromInvestment: "-13000000000",
        cashflowFromFinancing: "-17000000000",
        dividendPayoutCommonStock: "-4800000000",
        paymentsForRepurchaseOfCommonStock: "-10000000000",
        changeInCashAndCashEquivalents: "10200000000",
      },
      {
        fiscalDateEnding: "2021-12-31",
        operatingCashflow: "34000000000",
        capitalExpenditures: "-8600000000",
        cashflowFromInvestment: "-11000000000",
        cashflowFromFinancing: "-14500000000",
        dividendPayoutCommonStock: "-4000000000",
        paymentsForRepurchaseOfCommonStock: "-8500000000",
        changeInCashAndCashEquivalents: "8500000000",
      },
      {
        fiscalDateEnding: "2020-12-31",
        operatingCashflow: "28500000000",
        capitalExpenditures: "-7200000000",
        cashflowFromInvestment: "-9000000000",
        cashflowFromFinancing: "-12000000000",
        dividendPayoutCommonStock: "-3400000000",
        paymentsForRepurchaseOfCommonStock: "-7000000000",
        changeInCashAndCashEquivalents: "7500000000",
      },
      {
        fiscalDateEnding: "2019-12-31",
        operatingCashflow: "23800000000",
        capitalExpenditures: "-6000000000",
        cashflowFromInvestment: "-7500000000",
        cashflowFromFinancing: "-10000000000",
        dividendPayoutCommonStock: "-2800000000",
        paymentsForRepurchaseOfCommonStock: "-5800000000",
        changeInCashAndCashEquivalents: "6300000000",
      },
    ],
  },
};

mockStockData.income_statement.quarterlyReports = [
  // 2023
  {
    fiscalDateEnding: "2023-12-31",
    totalRevenue: "29000000000",
    costOfRevenue: "10500000000",
    grossProfit: "18500000000",
    operatingExpenses: "10200000000",
    researchAndDevelopment: "4100000000",
    sellingGeneralAndAdministrative: "5500000000",
    operatingIncome: "8300000000",
    interestExpense: "280000000",
    ebitda: "9500000000",
    ebit: "8300000000",
    incomeBeforeTax: "8020000000",
    incomeTaxExpense: "1200000000",
    netIncome: "6820000000"
  },
  {
    fiscalDateEnding: "2023-09-30",
    totalRevenue: "27500000000",
    costOfRevenue: "9900000000",
    grossProfit: "17600000000",
    operatingExpenses: "9700000000",
    researchAndDevelopment: "3900000000",
    sellingGeneralAndAdministrative: "5200000000",
    operatingIncome: "7900000000",
    interestExpense: "260000000",
    ebitda: "9000000000",
    ebit: "7900000000",
    incomeBeforeTax: "7640000000",
    incomeTaxExpense: "1150000000",
    netIncome: "6490000000"
  },
  {
    fiscalDateEnding: "2023-06-30",
    totalRevenue: "26200000000",
    costOfRevenue: "9500000000",
    grossProfit: "16700000000",
    operatingExpenses: "9200000000",
    researchAndDevelopment: "3700000000",
    sellingGeneralAndAdministrative: "4900000000",
    operatingIncome: "7500000000",
    interestExpense: "240000000",
    ebitda: "8600000000",
    ebit: "7500000000",
    incomeBeforeTax: "7260000000",
    incomeTaxExpense: "1090000000",
    netIncome: "6170000000"
  },
  {
    fiscalDateEnding: "2023-03-31",
    totalRevenue: "25300000000",
    costOfRevenue: "9200000000",
    grossProfit: "16100000000",
    operatingExpenses: "8900000000",
    researchAndDevelopment: "3600000000",
    sellingGeneralAndAdministrative: "4700000000",
    operatingIncome: "7200000000",
    interestExpense: "220000000",
    ebitda: "8300000000",
    ebit: "7200000000",
    incomeBeforeTax: "6980000000",
    incomeTaxExpense: "1050000000",
    netIncome: "5930000000"
  }
];

mockStockData.cash_flow.quarterlyReports = [
  // 2023
  {
    fiscalDateEnding: "2023-12-31",
    operatingCashflow: "12500000000",
    capitalExpenditures: "-3100000000",
    cashflowFromInvestment: "-4000000000",
    cashflowFromFinancing: "-5500000000",
    dividendPayoutCommonStock: "-1500000000",
    paymentsForRepurchaseOfCommonStock: "-3200000000",
    changeInCashAndCashEquivalents: "3000000000"
  },
  {
    fiscalDateEnding: "2023-09-30",
    operatingCashflow: "11800000000",
    capitalExpenditures: "-2900000000",
    cashflowFromInvestment: "-3800000000",
    cashflowFromFinancing: "-5200000000",
    dividendPayoutCommonStock: "-1400000000",
    paymentsForRepurchaseOfCommonStock: "-3000000000",
    changeInCashAndCashEquivalents: "2800000000"
  },
  {
    fiscalDateEnding: "2023-06-30",
    operatingCashflow: "11200000000",
    capitalExpenditures: "-2800000000",
    cashflowFromInvestment: "-3600000000",
    cashflowFromFinancing: "-4900000000",
    dividendPayoutCommonStock: "-1300000000",
    paymentsForRepurchaseOfCommonStock: "-2900000000",
    changeInCashAndCashEquivalents: "2700000000"
  },
  {
    fiscalDateEnding: "2023-03-31",
    operatingCashflow: "12000000000",
    capitalExpenditures: "-3000000000",
    cashflowFromInvestment: "-3900000000",
    cashflowFromFinancing: "-5300000000",
    dividendPayoutCommonStock: "-1400000000",
    paymentsForRepurchaseOfCommonStock: "-3100000000",
    changeInCashAndCashEquivalents: "2800000000"
  }
];

// Add mock data for dividends and analyst ratings
mockStockData.dividends = {
  data: [
    { ex_dividend_date: "2024-01-15", amount: "0.35" },
    { ex_dividend_date: "2023-10-15", amount: "0.35" },
    { ex_dividend_date: "2023-07-15", amount: "0.33" },
    // Add more historical dividend data
  ],
};

mockStockData.overview = {
  ...mockStockData.overview,
  AnalystRatingStrongBuy: "8",
  AnalystRatingBuy: "12",
  AnalystRatingHold: "5",
  AnalystRatingSell: "2",
  AnalystRatingStrongSell: "1",
  // Add all other required overview fields
};

const SideNavigation = () => {
  const [activeSection, setActiveSection] = useState("overview");
  const [showScrollTop, setShowScrollTop] = useState(false);

  const sections = [
    { id: "overview", label: "Company Overview", icon: AccountBalance },
    { id: "price-chart", label: "Price History", icon: Timeline },
    { id: "metrics", label: "Key Metrics", icon: Assessment },
    { id: "balance-sheet", label: "Balance Sheet", icon: AttachMoney },
    { id: "income-statement", label: "Income Statement", icon: AttachMoney },
    { id: "cash-flow", label: "Cash Flow", icon: AttachMoney },
    { id: "dividends", label: "Dividends", icon: AttachMoney },
    { id: "analyst-ratings", label: "Analyst Ratings", icon: Assessment },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        width: 240,
        flexShrink: 0,
        display: { xs: "none", lg: "block" },
        position: "sticky",
        top: 64,
        height: "calc(100vh - 64px)",
        overflowY: "auto",
        borderRight: "1px solid",
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
    >
      <List component="nav">
        {sections.map(({ id, label, icon: Icon }) => (
          <ListItemButton
            key={id}
            onClick={() => scrollToSection(id)}
            selected={activeSection === id}
          >
            <ListItemIcon>
              <Icon color={activeSection === id ? "primary" : "inherit"} />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
      {showScrollTop && (
        <Fab
          size="small"
          color="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          sx={{
            position: "fixed",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <KeyboardArrowUp />
        </Fab>
      )}
    </Box>
  );
};

const StatsCard = ({ title, value, icon: Icon }) => (
  <Paper
    sx={{
      p: 3,
      height: "100%",
      background: "linear-gradient(145deg, #ffffff 0%, #f9f9f9 100%)",
      boxShadow: "0 2px 10px rgba(0,0,0,0.08)",
      transition: "transform 0.2s",
      "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
      },
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Icon sx={{ mr: 1, color: "primary.main" }} />
      <Typography color="textSecondary" variant="subtitle2">
        {title}
      </Typography>
    </Box>
    <Typography variant="h4">{value}</Typography>
  </Paper>
);

const FinancialMetrics = ({ stock }) => (
  <Paper sx={{ p: 3 }}>
    <Typography variant="h6" gutterBottom>
      Financial Metrics
    </Typography>
    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
      <Box flex={1}>
        <Table size="small">
          <TableBody>
            {[
              ["Price to Book", stock.overview.PriceToBookRatio],
              [
                "Operating Margin",
                `${(stock.overview.OperatingMarginTTM * 100).toFixed(2)}%`,
              ],
              [
                "Profit Margin",
                `${(stock.overview.ProfitMargin * 100).toFixed(2)}%`,
              ],
            ].map(([label, value]) => (
              <TableRow key={label}>
                <TableCell>{label}</TableCell>
                <TableCell align="right">{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  </Paper>
);

const DemoStockDashboard = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
          color: "text.primary",
        }}
        elevation={0}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => navigate("/")}
            sx={{ mr: 2 }}
          >
            <ArrowBack />
          </IconButton>

          <img
            src={process.env.PUBLIC_URL + "/logo192.png"}
            alt="Logo"
            style={{
              height: isMobile ? "24px" : "32px",
              width: "auto",
              marginRight: isMobile ? "8px" : "16px",
            }}
          />

          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Demo Dashboard
          </Typography>

          {!isMobile && (
            <>
              <IconButton color="inherit">
                <Notifications />
              </IconButton>
              <IconButton color="inherit" onClick={handleMenu}>
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>Demo User</MenuItem>
                <MenuItem onClick={() => navigate("/")}>Exit Demo</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex", flexGrow: 1, mt: { xs: 7, sm: 8 } }}>
        {!isMobile && <SideNavigation />}
        {isMobile && (
          <SwipeableDrawer
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            onOpen={() => setMobileOpen(true)}
            sx={{
              "& .MuiDrawer-paper": {
                width: "80%",
                maxWidth: 300,
                mt: "56px",
                height: "calc(100% - 56px)",
              },
            }}
          >
            <SideNavigation />
          </SwipeableDrawer>
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: { xs: 1, sm: 2, md: 3 },
            pb: 15,
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Container maxWidth="lg">
            <Box id="overview">
              <Overview stock={mockStockData} />
            </Box>
            <Box id="price-chart">
              <PriceChart stockData={mockStockData} />
            </Box>
            <Box id="metrics">
              <KeyMetrics stock={mockStockData} />
            </Box>
            <Box id="balance-sheet">
              <BalanceSheetChart stockData={mockStockData} />
            </Box>
            <Box id="income-statement">
              <IncomeChart stockData={mockStockData} />
            </Box>
            <Box id="cash-flow">
              <CashFlowChart stockData={mockStockData} />
            </Box>
            <Box id="dividends">
              <DividendChart stockData={mockStockData} />
            </Box>
            <Box id="analyst-ratings">
              <AnalystRatings stock={mockStockData} />
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default DemoStockDashboard;
