// src/components/PricingPage.js
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Divider,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import NavBar from './NavBar';

const PricingPage = () => {
  const navigate = useNavigate();

  const tiers = [
    {
      title: "Monthly",
      price: "$5",
      period: "/month",
      // description: "Perfect for active traders",
      features: [
        "Historical quarterly and annual reports",
        "Dividend history",
        "Detailed financials",
        "Advanced comparison tools",
        "Company fundamentals",
        "Access all 7000+ stocks listed on NYSE and NASDAQ",
      ],
    },
    {
      title: "Annual",
      price: "$24",
      period: "/year",
      subtext: "Just $2/month",
      // description: "Best value for serious investors",
      features: [
        "Historical quarterly and annual reports",
        "Dividend history",
        "Detailed financials",
        "Advanced comparison tools",
        "Company fundamentals",
        "Access all 7000+ stocks listed on NYSE and NASDAQ",
      ],
      preferred: true,
    },
  ];

  return (
    <Box sx={{
      minHeight: "100vh",
      pt: 18,
      pb: 6,
      background: "linear-gradient(135deg, #ece9e6, #ffffff)",
    }}>
      <NavBar />
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" gutterBottom>
          Simple, Transparent Pricing
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" sx={{ mb: 6 }}>
          Choose the plan that works for you
        </Typography>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={4}
          justifyContent="center"
          alignItems="stretch"
        >
          {tiers.map((tier) => (
            <Box
              key={tier.title}
              sx={{
                flex: { xs: '1', sm: '0 1 calc(50% - 16px)', md: '0 1 calc(40% - 16px)' },
                display: 'flex'
              }}
            >
              <Card
                elevation={tier.preferred ? 8 : 2}
                sx={{
                  width: '100%',
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                {tier.preferred && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 12,
                      right: 12,
                      bgcolor: "secondary.main",
                      color: "white",
                      px: 2,
                      py: 0.5,
                      borderRadius: 1,
                    }}
                  >
                    Best Value
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1, p: 4 }}>
                  <Typography variant="h4" component="div" gutterBottom>
                    {tier.title}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", mb: 2 }}>
                    <Typography variant="h3" component="span">
                      {tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ ml: 1 }}>
                      {tier.period}
                    </Typography>
                  </Box>
                  {tier.subtext && (
                    <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
                      {tier.subtext}
                    </Typography>
                  )}
                  <Typography variant="subtitle1" sx={{ mb: 3 }}>
                    {tier.description}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <List>
                    {tier.features.map((feature) => (
                      <ListItem key={feature} sx={{ py: 1 }}>
                        <ListItemIcon>
                          <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardActions sx={{ p: 4, pt: 0 }}>
                  <Button
                    fullWidth
                    variant={tier.preferred ? "contained" : "outlined"}
                    color={tier.preferred ? "secondary" : "primary"}
                    size="large"
                    onClick={() => navigate("/register")}
                  >
                    Join Waitlist
                  </Button>
                </CardActions>
              </Card>
            </Box>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default PricingPage;
