import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import DemoStockDashboard from "./components/DemoStockDashboard";
import PricingPage from "./components/PricingPage";
import ProtectedRoute from "./components/ProtectedRoute";
import Compare from "./components/Compare";
import NotFound from "./components/NotFound";
import { AuthProvider } from "./contexts/AuthContext";
import { LightMode, DarkMode, Brightness4 } from "@mui/icons-material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import {
  ThemeProvider as CustomThemeProvider,
  useTheme,
} from "./contexts/ThemeContext";
import CssBaseline from "@mui/material/CssBaseline";
import { lightTheme, darkTheme, oledTheme } from "./theme/themes";

const ThemedApp = () => {
  const { themeMode } = useTheme();
  const theme = {
    light: lightTheme,
    dark: darkTheme,
    oled: oledTheme,
  }[themeMode];

  return (
    <>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/dashboard"
                element={
                  <MuiThemeProvider theme={theme}>
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  </MuiThemeProvider>
                }
              />

              <Route path="/demo" element={<DemoStockDashboard />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route
                path="/compare"
                element={
                  <MuiThemeProvider theme={theme}>
                    <ProtectedRoute>
                      <Compare />
                    </ProtectedRoute>
                  </MuiThemeProvider>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </>
  );
};

const App = () => {
  return (
    <CustomThemeProvider>
      <ThemedApp />
    </CustomThemeProvider>
  );
};

export default App;
