// src/components/Footer.js
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'primary.main', // Changed from secondary.main
        color: 'white'
      }}
    >
      <Typography variant="body2" align="center">
        © 2024 Lightspeed Insights. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
