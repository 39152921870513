// src/components/Dashboard.js
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Toolbar,
  Container,
  Autocomplete,
  TextField,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fab,
  ToggleButton,
  ToggleButtonGroup,
  Drawer,
  useMediaQuery,
  useTheme,
  Button,
  SwipeableDrawer,
} from "@mui/material";
import {
  Assessment,
  ShowChart,
  AccountCircle,
  Notifications,
  AccountBalance,
  Timeline,
  AttachMoney,
  Search,
  KeyboardArrowUp,
  LightMode,
  DarkMode,
  Brightness4,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { logout } from "../firebaseConfig";
import { useAuth } from "../contexts/AuthContext";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import db from "../firebaseConfig";
import PriceChart from "./dashboard/PriceChart";
import BalanceSheetChart from "./dashboard/BalanceSheetChart";
import IncomeChart from './dashboard/IncomeChart';
import CashFlowChart from './dashboard/CashFlowChart';
import { useTheme as useCustomTheme } from '../contexts/ThemeContext';
import Overview from './dashboard/Overview';
import KeyMetrics from './dashboard/KeyMetrics';
import AnalystRatings from './dashboard/AnalystRatings';
import DividendChart from './dashboard/DividendChart';
import { Link } from 'react-router-dom';

// Modify SideNavigation component in Dashboard.js
const SideNavigation = ({ selectedStock }) => {  // Add selectedStock prop
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('overview');

  // Separate scroll handler effect
  useEffect(() => {
    const handleScroll = ()  => {
      const scrollThreshold = 200;
      setShowScrollTop(window.scrollY > scrollThreshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Intersection observer effect - now depends on selectedStock
  useEffect(() => {
    // Reset active section when stock changes
    setActiveSection('overview');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: '-20% 0px -75% 0px'
      }
    );

    // Observe all sections
    sections.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => {
      sections.forEach(({ id }) => {
        const element = document.getElementById(id);
        if (element) observer.unobserve(element);
      });
    };
  }, [selectedStock]); // Add dependency

  const sections = [
    { id: 'overview', label: 'Company Overview', icon: AccountBalance },
    { id: 'price-chart', label: 'Price History', icon: Timeline },
    { id: 'metrics', label: 'Key Metrics', icon: Assessment },
    { id: 'balance-sheet', label: 'Balance Sheet', icon: AttachMoney },
    { id: 'income-statement', label: 'Income Statement', icon: AttachMoney },
    { id: 'cash-flow', label: 'Cash Flow', icon: AttachMoney },
    { id: 'dividends', label: 'Dividends', icon: AttachMoney },
    { id: 'analyst-ratings', label: 'Analyst Ratings', icon: Assessment }
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        width: 240,
        flexShrink: 0,
        display: { xs: 'none', lg: 'block' },
        position: 'sticky',
        top: 64,
        height: 'calc(100vh - 64px)',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
      }}
    >
      <List component="nav">
        {sections.map(({ id, label, icon: Icon }) => (
          <ListItemButton
            key={id}
            onClick={() => scrollToSection(id)}
            selected={activeSection === id}
            sx={{
              '&.Mui-selected': {
                bgcolor: 'secondary.extraLight',
                '&:hover': {
                  bgcolor: 'secondary.extraLight',
                },
              },
              '&:hover': {
                bgcolor: 'action.hover',
              }
            }}
          >
            <ListItemIcon>
              <Icon color={activeSection === id ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                color: activeSection === id ? 'primary' : 'inherit',
                fontWeight: activeSection === id ? 'bold' : 'normal',
              }}
            />
          </ListItemButton>
        ))}
      </List>
      {showScrollTop && (
        <Box sx={{
          position: 'sticky',
          bottom: 16,
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          transition: 'opacity 0.3s',
          opacity: showScrollTop ? 1 : 0,
        }}>
          <Fab
            size="small"
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            aria-label="scroll to top"
          >
            <KeyboardArrowUp />
          </Fab>
        </Box>
      )}
    </Box>
  );
};

// Update SearchBar styles for the topbar
const SearchBar = ({ onStockSelect }) => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { themeMode } = useCustomTheme();

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const stocksRef = collection(db, "stockList");
        const stocksSnapshot = await getDocs(stocksRef);
        const stocksData = stocksSnapshot.docs.map((doc) => doc.data());
        const stocksList = Object.keys(stocksData[0].tickers).map((ticker) => ({
          ticker,
          name: stocksData[0].tickers[ticker].name,
        }));
        setStocks(stocksList);
      } catch (error) {
        console.error("Error fetching stocks:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStocks();
  }, []);

  const handleStockSelect = async (ticker) => {
    if (!ticker) return;
    setLoading(true);
    try {
      const stockDoc = await getDoc(doc(db, "stockDetails", ticker));
      if (stockDoc.exists()) {
        onStockSelect(stockDoc.data());
      }
    } catch (error) {
      console.error("Error fetching stock data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      options={stocks}
      getOptionLabel={(option) => `${option.ticker} - ${option.name}`}
      loading={loading}
      onChange={(event, newValue) => {
        if (newValue) {
          handleStockSelect(newValue.ticker);
        }
      }}
      filterOptions={(options, { inputValue }) => {
        const input = inputValue.toLowerCase();
        // First find exact ticker matches
        const exactTickerMatches = options.filter(
          option => option.ticker.toLowerCase() === input
        );
        // Then find partial ticker matches
        const partialTickerMatches = options.filter(
          option => option.ticker.toLowerCase().includes(input) &&
            option.ticker.toLowerCase() !== input
        );
        // Finally find name matches
        const nameMatches = options.filter(
          option => option.name.toLowerCase().includes(input) &&
            !option.ticker.toLowerCase().includes(input)
        );

        return [...exactTickerMatches, ...partialTickerMatches, ...nameMatches];
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for companies..."
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Search
                color="action"
                sx={{
                  ml: 1,
                  mr: -0.5,
                  color: themeMode === 'light' ? 'action.active' : 'text.secondary'
                }}
              />
            ),
          }}
          sx={{
            width: "100%",
            maxWidth: "600px",
            backgroundColor: themeMode === 'light' ? 'background.paper' : 'background.default',
            '& .MuiOutlinedInput-root': {
              height: '40px',
              borderRadius: 2,
              '&:hover fieldset': {
                borderColor: 'primary.main',
              },
              '& fieldset': {
                borderColor: themeMode === 'light' ? 'divider' : 'action.selected',
              },
              '& input': {
                color: 'text.primary',
              },
            },
          }}
        />
      )}
    />
  );
};

// Add ThemeToggle component
const ThemeToggle = () => {
  const { themeMode, toggleTheme } = useCustomTheme();

  const handleThemeChange = (event, newMode) => {
    if (newMode !== null) {
      toggleTheme(newMode);
    }
  };

  return (
    <ToggleButtonGroup
      value={themeMode}
      exclusive
      onChange={handleThemeChange}
      size="small"
      sx={{ ml: 2 }}
    >
      <ToggleButton value="light" aria-label="light theme">
        <LightMode fontSize="small" />
      </ToggleButton>
      <ToggleButton value="dark" aria-label="dark theme">
        <DarkMode fontSize="small" />
      </ToggleButton>
      <ToggleButton value="oled" aria-label="oled theme">
        <Brightness4 fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

// Add MobileNavigation component
const MobileNavigation = ({ selectedStock, open, onClose, onOpen }) => {
  const sections = [
    { id: 'overview', label: 'Company Overview', icon: AccountBalance },
    { id: 'price-chart', label: 'Price History', icon: Timeline },
    { id: 'metrics', label: 'Key Metrics', icon: Assessment },
    { id: 'balance-sheet', label: 'Balance Sheet', icon: AttachMoney },
    { id: 'income-statement', label: 'Income Statement', icon: AttachMoney },
    { id: 'cash-flow', label: 'Cash Flow', icon: AttachMoney },
    { id: 'dividends', label: 'Dividends', icon: AttachMoney },
    { id: 'analyst-ratings', label: 'Analyst Ratings', icon: Assessment }
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      onClose();
    }
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: 300,
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }}
    >
      <List>
        {sections.map(({ id, label, icon: Icon }) => (
          <ListItemButton key={id} onClick={() => scrollToSection(id)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
    </SwipeableDrawer>
  );
};

// Modify the Dashboard component layout
const Dashboard = () => {
  const [selectedStock, setSelectedStock] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => logout(navigate);

  // Add these states
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // Add handlers
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleDrawerOpen = () => setMobileOpen(true);
  const handleDrawerClose = () => setMobileOpen(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          color: 'text.primary',
        }}
        elevation={0}
      >
        <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }}>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <img
            src={process.env.PUBLIC_URL + "/logo192.png"}
            alt="Lightspeed Insights Logo"
            style={{
              height: isMobile ? "24px" : "32px",
              width: "auto",
              marginRight: isMobile ? "8px" : "16px"
            }}
          />


            <Box sx={{ flexGrow: 1 }}>
              <SearchBar onStockSelect={setSelectedStock} />
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              component={Link}
              to="/dashboard"
              color="inherit"
              sx={{
                textTransform: 'none',
                fontWeight: (theme) =>
                  window.location.pathname === '/dashboard' ? 600 : 400,
                '&:hover': { backgroundColor: 'action.hover' }
              }}
            >
              Dashboard
            </Button>

            <Button
              component={Link}
              to="/compare"
              color="inherit"
              sx={{
                textTransform: 'none',
                fontWeight: (theme) =>
                  window.location.pathname === '/compare' ? 600 : 400,
                '&:hover': { backgroundColor: 'action.hover' }
              }}
            >
              Compare
            </Button>

          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isMobile && (
              <>
                <IconButton color="inherit">
                  <Notifications />
                </IconButton>
                <IconButton color="inherit" onClick={handleMenu}>
                  <AccountCircle />
                </IconButton>
                <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>Settings</MenuItem>
            <MenuItem>
              <ThemeToggle />
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
              </>
            )}
            {isMobile && (
              <IconButton color="inherit" onClick={handleMenu}>
                <AccountCircle />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexGrow: 1, mt: { xs: 7, sm: 8 } }}>
        {!isMobile && <SideNavigation selectedStock={selectedStock} />}
        {isMobile && (
          <MobileNavigation
            selectedStock={selectedStock}
            open={mobileOpen}
            onClose={handleDrawerClose}
            onOpen={handleDrawerOpen}
          />
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: { xs: 1, sm: 2, md: 3 },
            pb: 15,
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Container maxWidth="lg">
            {selectedStock && (
              <>
                <Box id="overview">
                  <Overview stock={selectedStock} />
                </Box>
                <Box id="price-chart">
                  <PriceChart stockData={selectedStock} />
                </Box>
                <Box id="metrics">
                  <KeyMetrics stock={selectedStock} />
                </Box>
                <Box id="balance-sheet">
                  <BalanceSheetChart stockData={selectedStock} />
                </Box>
                <Box id="income-statement">
                  <IncomeChart stockData={selectedStock} />
                </Box>
                <Box id="cash-flow">
                  <CashFlowChart stockData={selectedStock} />
                </Box>
                <Box id="dividends">
                  <DividendChart stockData={selectedStock} />
                </Box>
                <Box id="analyst-ratings">
                  <AnalystRatings stock={selectedStock} />
                </Box>
              </>
            )}
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
