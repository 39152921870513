// src/components/dashboard/IncomeChart.js
import React, { useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Paper,
  Typography,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { formatLargeNumber } from "../../constants/utils";
import ChartThemeWrapper from "./ChartThemeWrapper";

const IncomeChart = ({ stockData }) => {
  const [selectedCategory, setSelectedCategory] = useState("profitability");
  const [period, setPeriod] = useState("annual"); // 'quarterly' or 'annual'
  const [timeRange, setTimeRange] = useState("5Y");
  const [allSeriesVisible, setAllSeriesVisible] = useState(true);
  const chartRef = useRef(null);

  if (!stockData?.income_statement?.quarterlyReports?.length) {
    return null;
  }

  const metricsConfig = {
    comprehensiveIncomeNetOfTax: { label: "Comprehensive Income Net of Tax", color: "#2E7D32" },
    costOfRevenue: { label: "Cost of Revenue", color: "#1976D2" },
    costofGoodsAndServicesSold: { label: "Cost of Goods & Services Sold", color: "#D32F2F" },
    depreciation: { label: "Depreciation", color: "#FFA000" },
    depreciationAndAmortization: { label: "Depreciation & Amortization", color: "#9C27B0" },
    ebit: { label: "EBIT", color: "#00796B" },
    ebitda: { label: "EBITDA", color: "#E64A19" },
    fiscalDateEnding: { label: "Fiscal Date Ending", color: "#5D4037" },
    grossProfit: { label: "Gross Profit", color: "#0277BD" },
    incomeBeforeTax: { label: "Income Before Tax", color: "#558B2F" },
    incomeTaxExpense: { label: "Income Tax Expense", color: "#6A1B9A" },
    interestAndDebtExpense: { label: "Interest & Debt Expense", color: "#283593" },
    interestExpense: { label: "Interest Expense", color: "#1565C0" },
    interestIncome: { label: "Interest Income", color: "#2962FF" },
    investmentIncomeNet: { label: "Investment Income Net", color: "#00838F" },
    netIncome: { label: "Net Income", color: "#4527A0" },
    netIncomeFromContinuingOperations: { label: "Net Income from Continuing Operations", color: "#B71C1C" },
    netInterestIncome: { label: "Net Interest Income", color: "#827717" },
    nonInterestIncome: { label: "Non Interest Income", color: "#EF6C00" },
    operatingExpenses: { label: "Operating Expense", color: "#4A148C" },
    operatingIncome: { label: "Operating Income", color: "#006064" },
    otherNonOperatingIncome: { label: "Other Non Operating Income", color: "#1B5E20" },
    reportedCurrency: { label: "Reported Currency", color: "#880E4F" },
    researchAndDevelopment: { label: "Research & Development", color: "#BF360C" },
    sellingGeneralAndAdministrative: { label: "Selling, General & Admin", color: "#3E2723" },
    totalRevenue: { label: "Total Revenue", color: "#01579B" }
  };

  const metricCategories = {
    profitability: {
      label: "Profitability",
      metrics: ["totalRevenue", "grossProfit","ebitda", "ebit", "operatingIncome",  "netIncome"]
    },
    costs: {
      label: "Costs & Expenses",
      metrics: ["totalRevenue", "costOfRevenue", "costofGoodsAndServicesSold", "operatingExpenses", "researchAndDevelopment", "sellingGeneralAndAdministrative"]
    },
    interest: {
      label: "Interest & Investment",
      metrics: ["interestIncome", "interestExpense", "interestAndDebtExpense", "netInterestIncome", "investmentIncomeNet"]
    },
    taxAndDepreciation: {
      label: "Tax & Depreciation",
      metrics: ["incomeBeforeTax", "incomeTaxExpense", "depreciation", "depreciationAndAmortization"]
    }
  };

  const reports =
    period === "quarterly"
      ? stockData.income_statement.quarterlyReports
      : stockData.income_statement.annualReports;

  const maxValue = Math.max(
    ...reports.flatMap((report) =>
      Object.keys(metricsConfig).map((key) => parseFloat(report[key]) || 0)
    )
  );
  const { suffix } = formatLargeNumber(maxValue);
  const divisor =
    suffix === "B" ? 1e9 : suffix === "M" ? 1e6 : suffix === "K" ? 1e3 : 1;

  const processedData = reports
    .sort((a, b) => new Date(a.fiscalDateEnding) - new Date(b.fiscalDateEnding))
    .reduce((acc, report) => {
      Object.entries(metricsConfig).forEach(([key]) => {
        if (!acc[key]) acc[key] = [];
        acc[key].push([
          new Date(report.fiscalDateEnding).getTime(),
          parseFloat(report[key]) / divisor || 0,
        ]);
      });
      return acc;
    }, {});

  const filterDataByRange = (data, range) => {
    if (range === "ALL") return data;

    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
    const cutoffTime = fiveYearsAgo.getTime();

    return data.filter(point => point[0] >= cutoffTime);
  };

  const options = {
    title: {
      text: `Income Statement`,
    },
    chart: {
      type: "column",
      style: { fontFamily: "Roboto, Arial, sans-serif" },
      height: "400px",
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          return period === "annual"
            ? date.getFullYear().toString()
            : `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear()}`;
        },
      },
    },
    yAxis: {
      title: { text: `Amount (${suffix})` },
      labels: {
        formatter: function () {
          return `$${this.value.toFixed(1)}${suffix}`;
        },
      },
    },
    series: metricCategories[selectedCategory].metrics.map((metric) => ({
      name: metricsConfig[metric].label,
      data: filterDataByRange(processedData[metric], timeRange),
      color: metricsConfig[metric].color,
    })),
    tooltip: {
      shared: true,
      formatter: function () {
        const date = new Date(this.x);
        const periodLabel =
          period === "annual"
            ? date.getFullYear().toString()
            : `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear()}`;
        let rows = [`<b>${periodLabel}</b>`];
        this.points.forEach((point) => {
          rows.push(`${point.series.name}: $${point.y.toFixed(2)}${suffix}`);
        });
        return rows.join("<br/>");
      },
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        events: {
          hide: function() {
            const chart = this.chart;
            const allHidden = chart.series.every(s => !s.visible);
            if (allHidden) setAllSeriesVisible(false);
          },
          show: function() {
            const chart = this.chart;
            const allVisible = chart.series.every(s => s.visible);
            if (allVisible) setAllSeriesVisible(true);
          }
        }
      }
    },
  };

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod) setPeriod(newPeriod);
  };

  const handleToggleAll = (event) => {
    const chart = chartRef.current?.chart;
    if (!chart) return;

    const visible = event.target.checked;
    chart.series.forEach(series => {
      series.setVisible(visible, false);
    });
    chart.redraw();
    setAllSeriesVisible(visible);
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <ChartThemeWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {/* <Typography variant="h6">Income Statement</Typography> */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <ButtonGroup size="small">
              {Object.entries(metricCategories).map(([key, { label }]) => (
                <Button
                  key={key}
                  variant={selectedCategory === key ? "contained" : "outlined"}
                  onClick={() => setSelectedCategory(key)}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>

            <ButtonGroup size="small">
              <Button
                variant={period === "quarterly" ? "contained" : "outlined"}
                onClick={() => setPeriod("quarterly")}
              >
                Quarterly
              </Button>
              <Button
                variant={period === "annual" ? "contained" : "outlined"}
                onClick={() => setPeriod("annual")}
              >
                Annual
              </Button>
            </ButtonGroup>

            <ButtonGroup size="small">
              <Button
                variant={timeRange === "5Y" ? "contained" : "outlined"}
                onClick={() => setTimeRange("5Y")}
              >
                5Y
              </Button>
              <Button
                variant={timeRange === "ALL" ? "contained" : "outlined"}
                onClick={() => setTimeRange("ALL")}
              >
                ALL
              </Button>
            </ButtonGroup>
          </Box>
        </Box>

        <Box sx={{ height: "400px" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        </Box>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={allSeriesVisible}
              onChange={handleToggleAll}
            />
          }
          label="Show/Hide All"
        />
      </ChartThemeWrapper>
    </Paper>
  );
};

export default IncomeChart;
