// src/components/LandingPage.js
import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import {
  ShowChart,
  History,
  Payments,
  Analytics,
  Business,
  CompareArrows,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <NavBar />

      {/* Hero Section */}
      <Box
        sx={{
          pt: { xs: 10, md: 20 },
          pb: 6,
          background: "linear-gradient(45deg, #212121 30%, #424242 90%)",
          color: "white",
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" align="center" component="h1" gutterBottom>
            Complete Market Intelligence
          </Typography>
          <Typography variant="h5" align="center" sx={{ mb: 4 }}>
            Access comprehensive data for all 7000+ NYSE and NASDAQ stocks
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => navigate("/register")}
            >
              Join Waitlist
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              onClick={() => navigate("/demo")}
              sx={{
                borderColor: "white",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255,255,255,0.1)",
                },
              }}
            >
              View Demo
            </Button>
          </Stack>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }} id="features">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: { xs: "none", md: "scale(1.02)" },
                  boxShadow: 5,
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <History fontSize="large" color="primary" />
                <Typography variant="h5" component="h2" sx={{ my: 2 }}>
                  Historical Reports
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Access detailed quarterly and annual reports dating back 10+ years. Track company performance through comprehensive historical data and financial statements.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: { xs: "none", md: "scale(1.02)" },
                  boxShadow: 5,
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Payments fontSize="large" color="primary" />
                <Typography variant="h5" component="h2" sx={{ my: 2 }}>
                  Dividend Analytics
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Complete dividend history with yield analysis, payout ratios, and growth trends. Make informed decisions with our dividend forecasting tools.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: { xs: "none", md: "scale(1.02)" },
                  boxShadow: 5,
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <ShowChart fontSize="large" color="primary" />
                <Typography variant="h5" component="h2" sx={{ my: 2 }}>
                  Detailed Financials
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Deep dive into balance sheets, income statements, and cash flow analysis with interactive charts and customizable metrics.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: { xs: "none", md: "scale(1.02)" },
                  boxShadow: 5,
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <CompareArrows fontSize="large" color="primary" />
                <Typography variant="h5" component="h2" sx={{ my: 2 }}>
                  Advanced Comparison
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Side-by-side analysis tools to compare multiple companies across key metrics, ratios, and performance indicators.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: { xs: "none", md: "scale(1.02)" },
                  boxShadow: 5,
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Business fontSize="large" color="primary" />
                <Typography variant="h5" component="h2" sx={{ my: 2 }}>
                  Company Fundamentals
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Essential metrics, growth rates, profitability analysis, and risk indicators all in one place for smarter investment decisions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: { xs: "none", md: "scale(1.02)" },
                  boxShadow: 5,
                },
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Analytics fontSize="large" color="primary" />
                <Typography variant="h5" component="h2" sx={{ my: 2 }}>
                  Complete Coverage
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Comprehensive data for over 7000 stocks listed on NYSE and NASDAQ, updated with institutional-grade accuracy.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
};

export default LandingPage;
