// src/components/dashboard/CashFlowChart.js
import React, { useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Paper,
  Typography,
  Box,
  ButtonGroup,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { formatLargeNumber } from "../../constants/utils";
import ChartThemeWrapper from "./ChartThemeWrapper";

const CashFlowChart = ({ stockData }) => {
  const categories = {
    operations: {
      label: "Operations",
      metrics: [
        "netIncome",
        "operatingCashflow",
        "cashflowFromInvestment",
        "cashflowFromFinancing",
        "changeInCashAndCashEquivalents",
      ],
    },
    investment: {
      label: "Investment",
      metrics: [
        "capitalExpenditures",
        "cashflowFromInvestment",
        "depreciationDepletionAndAmortization",
      ],
    },
    financing: {
      label: "Financing",
      metrics: [
        "cashflowFromFinancing",
        "dividendPayoutCommonStock",
        "dividendPayoutPreferredStock",
        "proceedsFromIssuanceOfCommonStock",
        "proceedsFromIssuanceOfPreferredStock",
        "proceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet",
      ],
    },
    equity: {
      label: "Equity",
      metrics: [
        "paymentsForRepurchaseOfCommonStock",
        "paymentsForRepurchaseOfPreferredStock",
        "proceedsFromSaleOfTreasuryStock",
        "proceedsFromIssuanceOfCommonStock",
        "proceedsFromIssuanceOfPreferredStock",
      ],
    },
    // other: {
    //   label: "Other",
    //   metrics: ["proceedsFromRepaymentsOfShortTermDebt", "changeInOperatingAssets", "changeInOperatingLiabilities", "changeInInventory", "changeInReceivables"]
    // }
  };

  const [selectedCategory, setSelectedCategory] = useState("operations");
  const [period, setPeriod] = useState("annual"); // Add period state
  const [timeRange, setTimeRange] = useState("5Y");
  const [allSeriesVisible, setAllSeriesVisible] = useState(true);
  const chartRef = useRef(null);

  // Check for the new data structure
  if (
    !stockData?.cash_flow?.quarterlyReports?.length &&
    !stockData?.cash_flow?.annualReports?.length
  ) {
    return null;
  }

  const metricsConfig = {
    capitalExpenditures: { label: "Capital Expenditure", color: "#D32F2F" },
    cashflowFromFinancing: {
      label: "Cash Flow from Financing",
      color: "#FFA000",
    },
    cashflowFromInvestment: {
      label: "Cash Flow from Investment",
      color: "#1976D2",
    },
    changeInCashAndCashEquivalents: {
      label: "Change in Cash & Cash Equivalents",
      color: "#2E7D32",
    },
    changeInExchangeRate: {
      label: "Change in Exchange Rate",
      color: "#9C27B0",
    },
    changeInInventory: { label: "Change in Inventory", color: "#FF5722" },
    changeInOperatingAssets: {
      label: "Change in Operating Assets",
      color: "#795548",
    },
    changeInOperatingLiabilities: {
      label: "Change in Operating Liabilities",
      color: "#607D8B",
    },
    changeInReceivables: { label: "Change in Receivables", color: "#F44336" },
    depreciationDepletionAndAmortization: {
      label: "Depreciation & Amortization",
      color: "#673AB7",
    },
    dividendPayout: { label: "Dividend Payout", color: "#3F51B5" },
    dividendPayoutCommonStock: {
      label: "Dividend Payout Common Stock",
      color: "#2196F3",
    },
    dividendPayoutPreferredStock: {
      label: "Dividend Payout Preferred Stock",
      color: "#03A9F4",
    },
    netIncome: { label: "Net Income", color: "#00BCD4" },
    operatingCashflow: { label: "Cash Flow from Operations", color: "#009688" },
    paymentsForOperatingActivities: {
      label: "Payments for Operating Activities",
      color: "#4CAF50",
    },
    paymentsForRepurchaseOfCommonStock: {
      label: "Repurchase of Common Stock",
      color: "#8BC34A",
    },
    paymentsForRepurchaseOfEquity: {
      label: "Repurchase of Equity",
      color: "#CDDC39",
    },
    paymentsForRepurchaseOfPreferredStock: {
      label: "Repurchase of Preferred Stock",
      color: "#FFC107",
    },
    proceedsFromIssuanceOfCommonStock: {
      label: "Proceeds from Issuance of Common Stock",
      color: "#FF9800",
    },
    proceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet: {
      label: "Proceeds from Issuance of Long Term Debt",
      color: "#FF5722",
    },
    proceedsFromIssuanceOfPreferredStock: {
      label: "Proceeds from Issuance of Preferred Stock",
      color: "#795548",
    },
    proceedsFromOperatingActivities: {
      label: "Proceeds from Operating Activities",
      color: "#9E9E9E",
    },
    proceedsFromRepaymentsOfShortTermDebt: {
      label: "Proceeds from Repayments of Short Term Debt",
      color: "#607D8B",
    },
    proceedsFromRepurchaseOfEquity: {
      label: "Proceeds from Repurchase of Equity",
      color: "#E91E63",
    },
    proceedsFromSaleOfTreasuryStock: {
      label: "Proceeds from Sale of Treasury Stock",
      color: "#9C27B0",
    },
    profitLoss: { label: "Profit/Loss", color: "#673AB7" },
  };

  // Get reports based on selected period
  const reports =
    period === "quarterly"
      ? stockData.cash_flow.quarterlyReports
      : stockData.cash_flow.annualReports;

  const maxValue = Math.max(
    ...reports.flatMap((report) =>
      Object.keys(metricsConfig).map((key) => parseFloat(report[key]) || 0)
    )
  );
  const { suffix } = formatLargeNumber(maxValue);
  const divisor =
    suffix === "B" ? 1e9 : suffix === "M" ? 1e6 : suffix === "K" ? 1e3 : 1;

  const processedData = reports
    .sort((a, b) => new Date(a.fiscalDateEnding) - new Date(b.fiscalDateEnding))
    .reduce((acc, report) => {
      Object.entries(metricsConfig).forEach(([key]) => {
        if (!acc[key]) acc[key] = [];
        const value = parseFloat(report[key]?.replace(/,/g, "")) / divisor || 0;
        acc[key].push([new Date(report.fiscalDateEnding).getTime(), value]);
      });
      return acc;
    }, {});

  // Add filterDataByRange helper function
  const filterDataByRange = (data, range) => {
    if (range === "ALL") return data;

    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
    const cutoffTime = fiveYearsAgo.getTime();

    return data.filter((point) => point[0] >= cutoffTime);
  };

  // Add validation when mapping series data
  const series = categories[selectedCategory].metrics
    .filter((metric) => metricsConfig[metric]) // Filter out invalid metrics
    .map((metric) => ({
      name: metricsConfig[metric].label,
      data: filterDataByRange(processedData[metric], timeRange),
      color: metricsConfig[metric].color,
    }));

  const options = {
    title: {
      text: `Cash Flow`,
    },
    chart: {
      type: "column",
      style: { fontFamily: "Roboto, Arial, sans-serif" },
      height: "400px",
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          return period === "annual"
            ? date.getFullYear().toString()
            : `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear()}`;
        },
      },
    },
    yAxis: {
      title: { text: `Amount (${suffix})` },
      labels: {
        formatter: function () {
          return `$${this.value.toFixed(1)}${suffix}`;
        },
      },
    },
    series: series,
    tooltip: {
      shared: true,
      formatter: function () {
        const date = new Date(this.x);
        const periodLabel =
          period === "annual"
            ? date.getFullYear().toString()
            : `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear()}`;
        const rows = [`<b>${periodLabel}</b>`];
        this.points.forEach((point) => {
          rows.push(`${point.series.name}: $${point.y.toFixed(2)}${suffix}`);
        });
        return rows.join("<br/>");
      },
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        events: {
          hide: function () {
            const chart = this.chart;
            const allHidden = chart.series.every((s) => !s.visible);
            if (allHidden) setAllSeriesVisible(false);
          },
          show: function () {
            const chart = this.chart;
            const allVisible = chart.series.every((s) => s.visible);
            if (allVisible) setAllSeriesVisible(true);
          },
        },
      },
    },
  };

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod) setPeriod(newPeriod);
  };

  const handleToggleAll = (event) => {
    const chart = chartRef.current?.chart;
    if (!chart) return;

    const visible = event.target.checked;
    chart.series.forEach((series) => {
      series.setVisible(visible, false);
    });
    chart.redraw();
    setAllSeriesVisible(visible);
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <ChartThemeWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {/* <Typography variant="h6">Cash Flow</Typography> */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <ButtonGroup size="small" variant="outlined">
              {Object.entries(categories).map(([key, { label }]) => (
                <Button
                  key={key}
                  onClick={() => setSelectedCategory(key)}
                  variant={selectedCategory === key ? "contained" : "outlined"}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>

            <ButtonGroup size="small" variant="outlined">
              <Button
                onClick={() => setPeriod("quarterly")}
                variant={period === "quarterly" ? "contained" : "outlined"}
              >
                Quarterly
              </Button>
              <Button
                onClick={() => setPeriod("annual")}
                variant={period === "annual" ? "contained" : "outlined"}
              >
                Annual
              </Button>
            </ButtonGroup>

            <ButtonGroup size="small" variant="outlined">
              <Button
                onClick={() => setTimeRange("5Y")}
                variant={timeRange === "5Y" ? "contained" : "outlined"}
              >
                5Y
              </Button>
              <Button
                onClick={() => setTimeRange("ALL")}
                variant={timeRange === "ALL" ? "contained" : "outlined"}
              >
                ALL
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Box sx={{ height: "400px" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        </Box>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={allSeriesVisible}
              onChange={handleToggleAll}
            />
          }
          label="Show/Hide All"
        />
      </ChartThemeWrapper>
    </Paper>
  );
};

export default CashFlowChart;
