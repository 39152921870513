// src/components/dashboard/DividendChart.js
import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Paper, Typography, Box, Button, ButtonGroup
} from "@mui/material";
import ChartThemeWrapper from "./ChartThemeWrapper";

const DividendChart = ({ stockData }) => {
  const [timeRange, setTimeRange] = useState("5Y");

  if (!stockData?.dividends?.data?.length) return null;

  // Helper function to adjust for splits
  const adjustForSplits = (dividendData, splits) => {
    if (!splits?.length) return dividendData;

    const splitFactors = splits.map(split => ({
      date: new Date(split.effective_date).getTime(),
      factor: parseFloat(split.split_factor)
    })).sort((a, b) => b.date - a.date); // Sort newest to oldest

    return dividendData.map(([date, amount]) => {
      const appliedSplits = splitFactors.filter(split => split.date > date);
      const totalSplitFactor = appliedSplits.reduce((acc, split) => acc * split.factor, 1);
      return [date, amount / totalSplitFactor];
    });
  };

  const filterDataByRange = (data, range) => {
    if (range === "ALL") return data;
    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
    return data.filter(d => d[0] >= fiveYearsAgo.getTime());
  };

  const processedData = filterDataByRange(
    adjustForSplits(
      stockData.dividends.data.map(dividend => [
        new Date(dividend.ex_dividend_date).getTime(),
        parseFloat(dividend.amount)
      ]).sort((a, b) => a[0] - b[0]),
      stockData.splits?.data
    ),
    timeRange
  );

  // Process splits for markers
//   const splitMarkers = stockData.splits?.data?.map(split => ({
//     color: '#000000',
//     width: 2,
//     value: new Date(split.effective_date).getTime(),
//     label: {
//       text: `${parseInt(split.split_factor)}:1 split`,
//       style: { color: '#000000' }
//     }
//   })) || [];

  const options = {
    title: { text: "Split-Adjusted Dividend History" },
    chart: {
      type: "column",
      style: { fontFamily: "Roboto, Arial, sans-serif" },
      height: "400px"
    },
    xAxis: {
      type: "datetime",
      title: { text: "Ex-Dividend Date" },
      labels: { format: "{value:%Y-%m}" },
    //   plotLines: splitMarkers
    },
    yAxis: {
      title: { text: "Dividend Amount ($)" },
      labels: {
        formatter: function() {
          return `$${this.value.toFixed(4)}`;
        }
      }
    },
    series: [{
      name: "Split-Adjusted Dividend",
      data: processedData,
      color: "#4CAF50"
    }],
    tooltip: {
      formatter: function() {
        return `<b>$${this.y.toFixed(4)}</b><br/>
                ${Highcharts.dateFormat('%Y-%m-%d', this.x)}`;
      }
    },
    credits: { enabled: false }
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <ChartThemeWrapper>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2
        }}>
          <ButtonGroup size="small">
            <Button
              variant={timeRange === "5Y" ? "contained" : "outlined"}
              onClick={() => setTimeRange("5Y")}
            >
              5Y
            </Button>
            <Button
              variant={timeRange === "ALL" ? "contained" : "outlined"}
              onClick={() => setTimeRange("ALL")}
            >
              ALL
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ height: "400px" }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
      </ChartThemeWrapper>
    </Paper>
  );
};

export default DividendChart;
