// src/components/NavBar.js
import React, { useState } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    handleClose();
    navigate("/login");
  };

  const handleRegister = () => {
    handleClose();
    navigate("/register");
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "primary.main", boxShadow: 2 }}>
      <Toolbar>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            flexGrow: 1,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.9
            }
          }}
          onClick={() => navigate('/')}
        >
          <img
            src={process.env.PUBLIC_URL + "/logo192.png"}
            alt="Lightspeed Insights Logo"
            style={{ height: "32px", width: "auto" }}
          />
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Lightspeed Insights
          </Typography>
        </Stack>

        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={handleMenu} edge="end">
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component="a" href="/demo">
                Demo
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="/pricing">
                Pricing
              </MenuItem>
              <MenuItem onClick={handleLogin}>Admin Login</MenuItem>
              <MenuItem onClick={handleRegister}>Join Waitlist</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" href="/demo">
              Demo
            </Button>
            <Button color="inherit" href="/pricing">
              Pricing
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 2, color: "white", borderColor: "white" }}
              onClick={() => navigate("/login")}
            >
              VIP Login
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 2, color: "white", borderColor: "white" }}
              onClick={() => navigate("/register")}
              color="secondary"
            >
              Join Waitlist
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
