// src/components/dashboard/BalanceSheetChart.js
import React, { useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Paper,
  Typography,
  Box,
  ButtonGroup, // Remove ToggleButtonGroup and ToggleButton
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { formatLargeNumber } from "../../constants/utils";
import ChartThemeWrapper from "./ChartThemeWrapper";

const VIEW_MODES = {
  OVERVIEW: "overview",
  CURRENT: "current",
  NON_CURRENT: "non_current",
  SHARES_OUTSTANDING: "shares_outstanding",
};

const METRIC_GROUPS = {
  overview: {
    totalAssets: { label: "Total Assets", color: "#D32F2F" },
    totalLiabilities: { label: "Total Liabilities", color: "#1976D2" },
    totalShareholderEquity: { label: "Total Shareholder Equity", color: "#388E3C" },
  },
  current: {
    // Current Assets
    totalCurrentAssets: { label: "Total Current Assets", color: "#1976D2" },
    cashAndCashEquivalentsAtCarryingValue: { label: "Cash & Equivalents", color: "#388E3C" },
    currentNetReceivables: { label: "Net Receivables", color: "#9C27B0" },
    inventory: { label: "Inventory", color: "#FF5722" },
    otherCurrentAssets: { label: "Other Current Assets", color: "#FFEB3B" },
    shortTermInvestments: { label: "Short Term Investments", color: "#536DFE" },

    // Current Liabilities
    totalCurrentLiabilities: { label: "Total Current Liabilities", color: "#D32F2F" },
    currentDebt: { label: "Current Debt", color: "#1976D2" },
    currentAccountsPayable: { label: "Accounts Payable", color: "#388E3C" },
    deferredRevenue: { label: "Deferred Revenue", color: "#FFA000" },
    otherCurrentLiabilities: { label: "Other Current Liabilities", color: "#9C27B0" },
  },
  non_current: {
    // Non-Current Assets
    totalNonCurrentAssets: { label: "Total Non-Current Assets", color: "#607D8B" },
    propertyPlantEquipment: { label: "Property Plant & Equipment", color: "#F44336" },
    // accumulatedDepreciationAmortizationPPE: { label: "Accumulated Depreciation", color: "#D32F2F" },
    goodwill: { label: "Goodwill", color: "#2196F3" },
    intangibleAssets: { label: "Intangible Assets", color: "#2196F3" },
    intangibleAssetsExcludingGoodwill: { label: "Intangible Assets excl. Goodwill", color: "#4CAF50" },
    longTermInvestments: { label: "Long Term Investments", color: "#607D8B" },
    // investments: { label: "Investments", color: "#9C27B0" },
    otherNonCurrentAssets: { label: "Other Non-Current Assets", color: "#8BC34A" },

    // Non-Current Liabilities
    totalNonCurrentLiabilities: { label: "Total Non-Current Liabilities", color: "#9C27B0" },
    longTermDebt: { label: "Long Term Debt", color: "#FF5722" },
    otherNonCurrentLiabilities: { label: "Other Non-Current Liabilities", color: "#3F51B5" },
  },
  shares_outstanding: {
    commonStockSharesOutstanding: { label: "Common Stock Shares Outstanding", color: "#9C27B0" },
  }
};

// Add split adjustment logic
const adjustForSplits = (sharesData, splits) => {
  if (!sharesData || !sharesData.length) return [];
  if (!splits || !splits.length) return sharesData;

  // Sort shares data by date
  const sortedShares = [...sharesData].sort((a, b) => a[0] - b[0]);

  const splitFactors = splits.map(split => ({
    date: new Date(split.effective_date).getTime(),
    factor: parseFloat(split.split_factor)
  })).sort((a, b) => a.date - b.date); // Sort oldest to newest

  // Calculate cumulative split factor from oldest to newest
  return sortedShares.map(([date, shares]) => {
    const totalSplitFactor = splitFactors.reduce((acc, split) => {
      // if (date < split.date - 1664000000) {
      if (date < split.date - (1664000000)) {
        return acc * split.factor;
      }
      return acc;
    }, 1);

    const adjustedShares = shares * totalSplitFactor;
    return [date, adjustedShares];
  });
};

// Add this helper function after the adjustForSplits function
const consolidateAnnualData = (reports) => {
  // Group reports by year
  const yearlyData = reports.reduce((acc, report) => {
    const year = new Date(report.fiscalDateEnding).getFullYear();
    if (!acc[year]) acc[year] = [];
    acc[year].push(report);
    return acc;
  }, {});

  // For each year, use the latest report
  return Object.entries(yearlyData)
    .map(([year, reports]) => {
      // Sort by date descending and take the first (most recent) report
      return reports.sort((a, b) =>
        new Date(b.fiscalDateEnding) - new Date(a.fiscalDateEnding)
      )[0];
    })
    .sort((a, b) =>
      new Date(a.fiscalDateEnding) - new Date(b.fiscalDateEnding)
    );
};

const BalanceSheetChart = ({ stockData }) => {
  const [period, setPeriod] = useState("annual"); // 'quarterly' or 'annual'
  const [viewMode, setViewMode] = useState(VIEW_MODES.OVERVIEW);
  const [allSeriesVisible, setAllSeriesVisible] = useState(true);
  const [timeRange, setTimeRange] = useState("5Y");
  const chartRef = useRef(null);
  const selectedMetrics = Object.keys(METRIC_GROUPS[viewMode]); // Always show all metrics

  if (!stockData?.balance_sheet) return null;

  const metricsConfig = {
    accumulatedDepreciationAmortizationPPE: { label: "Accumulated Depreciation & Amortization", color: "#D32F2F" },
    capitalLeaseObligations: { label: "Capital Lease Obligations", color: "#1976D2" },
    cashAndCashEquivalentsAtCarryingValue: { label: "Cash & Equivalents", color: "#388E3C" },
    cashAndShortTermInvestments: { label: "Cash & Short Term Investments", color: "#FFA000" },
    commonStock: { label: "Common Stock", color: "#9C27B0" },
    commonStockSharesOutstanding: { label: "Common Stock Shares Outstanding", color: "#FF5722" },
    currentAccountsPayable: { label: "Current Accounts Payable", color: "#607D8B" },
    currentDebt: { label: "Current Debt", color: "#795548" },
    currentLongTermDebt: { label: "Current Long Term Debt", color: "#009688" },
    currentNetReceivables: { label: "Current Net Receivables", color: "#673AB7" },
    deferredRevenue: { label: "Deferred Revenue", color: "#E91E63" },
    goodwill: { label: "Goodwill", color: "#9C27B0" },
    intangibleAssets: { label: "Intangible Assets", color: "#2196F3" },
    intangibleAssetsExcludingGoodwill: { label: "Intangible Assets Excluding Goodwill", color: "#4CAF50" },
    inventory: { label: "Inventory", color: "#FF9800" },
    investments: { label: "Investments", color: "#9C27B0" },
    longTermDebt: { label: "Long Term Debt", color: "#FF5722" },
    longTermDebtNoncurrent: { label: "Long Term Debt Noncurrent", color: "#795548" },
    longTermInvestments: { label: "Long Term Investments", color: "#607D8B" },
    otherCurrentAssets: { label: "Other Current Assets", color: "#00BCD4" },
    otherCurrentLiabilities: { label: "Other Current Liabilities", color: "#FFEB3B" },
    otherNonCurrentAssets: { label: "Other Non Current Assets", color: "#8BC34A" },
    otherNonCurrentLiabilities: { label: "Other Non Current Liabilities", color: "#3F51B5" },
    propertyPlantEquipment: { label: "Property, Plant & Equipment", color: "#F44336" },
    retainedEarnings: { label: "Retained Earnings", color: "#9E9E9E" },
    shortLongTermDebtTotal: { label: "Short/Long Term Debt", color: "#FF4081" },
    shortTermDebt: { label: "Short Term Debt", color: "#7C4DFF" },
    shortTermInvestments: { label: "Short Term Investments", color: "#536DFE" },
    totalAssets: { label: "Total Assets", color: "#D32F2F" },
    totalCurrentAssets: { label: "Total Current Assets", color: "#1976D2" },
    totalCurrentLiabilities: { label: "Total Current Liabilities", color: "#388E3C" },
    totalLiabilities: { label: "Total Liabilities", color: "#FFA000" },
    totalNonCurrentAssets: { label: "Total Non Current Assets", color: "#9C27B0" },
    totalNonCurrentLiabilities: { label: "Total Non Current Liabilities", color: "#FF5722" },
    totalShareholderEquity: { label: "Total Shareholder Equity", color: "#607D8B" },
    treasuryStock: { label: "Treasury Stock", color: "#795548" },
  };

  // Replace the reports assignment with:
  let reports = period === "quarterly"
    ? stockData.balance_sheet.quarterlyReports
    : consolidateAnnualData(stockData.balance_sheet.annualReports);

  // Determine scale based on largest value
  const maxValue = Math.max(
    ...reports.flatMap((report) =>
      Object.keys(metricsConfig).map((key) => parseFloat(report[key]) || 0)
    )
  );
  const { suffix } = formatLargeNumber(maxValue);
  const divisor =
    suffix === "B" ? 1e9 : suffix === "M" ? 1e6 : suffix === "K" ? 1e3 : 1;

    const processedData = reports
    .sort((a, b) => new Date(a.fiscalDateEnding) - new Date(b.fiscalDateEnding))
    .reduce((acc, report) => {
      Object.entries(metricsConfig).forEach(([key, config]) => {
        if (!acc[key]) acc[key] = [];
        // Make liability metrics negative
        const value = parseFloat(report[key]) / divisor;
        const isLiability = key.toLowerCase().includes('liabilities') ||
                           key.includes('Debt') ||
                           key === 'currentAccountsPayable' ||
                           key === 'deferredRevenue';
        acc[key].push([
          new Date(report.fiscalDateEnding).getTime(),
          isLiability ? -Math.abs(value) : value,
        ]);
      });
      return acc;
    }, {});

  // Add this helper function near the top of the component
  const calculateYoYChange = (data, isPeriodQuarterly) => {
    const periodsPerYear = isPeriodQuarterly ? 4 : 1;

    return data.map((point, index) => {
      // Find index of same period last year
      const previousYearIndex = index - periodsPerYear;

      if (previousYearIndex < 0) return [point[0], null];

      const currentValue = point[1];
      const previousValue = data[previousYearIndex][1];
      const percentageChange = ((currentValue - previousValue) / previousValue) * 100;

      return [point[0], percentageChange];
    });
  };

  // Add function to filter data by date range
  const filterDataByRange = (data, range) => {
    if (range === "ALL") return data;

    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
    const cutoffTime = fiveYearsAgo.getTime();

    return data.filter(point => point[0] >= cutoffTime);
  };

  // Get shares and splits data
  const sharesData = processedData.commonStockSharesOutstanding || [];
  const splits = stockData.splits?.data || [];

  const options = {
    title: {
      text: `Balance Sheet`,
    },
    chart: {
      type: viewMode === VIEW_MODES.OVERVIEW ? "line" : "column",
      style: { fontFamily: "Roboto, Arial, sans-serif" },
      height: "400px",
    },
    // Update the xAxis configuration in options:
    xAxis: {
      type: "datetime",
      title: { text: "Date" },
      dateTimeLabelFormats: {
        month: period === "quarterly" ? "%Y-%m" : "%Y",
      },
      labels: {
        format: period === "quarterly" ? "{value:%Y-%m}" : "{value:%Y}",
      }
    },
    yAxis: viewMode === VIEW_MODES.SHARES_OUTSTANDING ? [
      {
        // Primary y-axis for share count
        title: { text: 'Shares' },
        labels: {
          formatter: function() {
            return Highcharts.numberFormat(this.value, 0);
          }
        }
      },
      {
        // Secondary y-axis for YoY change
        title: { text: 'YoY Change %' },
        opposite: true,
        labels: {
          formatter: function() {
            return `${this.value.toFixed(1)}%`;
          }
        }
      }
    ] : {
      title: { text: `Amount (${suffix})` },
      labels: {
        formatter: function() {
          const value = Math.abs(this.value);
          const sign = this.value < 0 ? '-' : '';
          return `${sign}$${value.toFixed(1)}${suffix}`;
        }
      }
    },
    series: (() => {
      switch(viewMode) {
        case VIEW_MODES.OVERVIEW:
          return selectedMetrics.map((metric) => ({
            name: METRIC_GROUPS[viewMode][metric].label,
            data: filterDataByRange(processedData[metric], timeRange),
            color: METRIC_GROUPS[viewMode][metric].color,
            type: 'column',
            // no stacking
            stacking: null,
            yAxis: 0,
            marker: { enabled: true },
          }));

        case VIEW_MODES.ASSETS:
          return [
            // Total metrics as lines
            {
              name: METRIC_GROUPS[viewMode].totalCurrentAssets.label,
              data: filterDataByRange(processedData.totalCurrentAssets, timeRange),
              color: METRIC_GROUPS[viewMode].totalCurrentAssets.color,
              type: 'line',
              yAxis: 0,
              marker: { enabled: true },
            },
            {
              name: METRIC_GROUPS[viewMode].totalNonCurrentAssets.label,
              data: filterDataByRange(processedData.totalNonCurrentAssets, timeRange),
              color: METRIC_GROUPS[viewMode].totalNonCurrentAssets.color,
              type: 'line',
              yAxis: 0,
              marker: { enabled: true },
            },
            // Component metrics as stacked columns
            ...selectedMetrics
              .filter(metric => !['totalCurrentAssets', 'totalNonCurrentAssets'].includes(metric))
              .map((metric) => ({
                name: METRIC_GROUPS[viewMode][metric].label,
                data: filterDataByRange(processedData[metric], timeRange),
                color: METRIC_GROUPS[viewMode][metric].color,
                type: 'column',
                stacking: 'normal',
              }))
          ];

        case VIEW_MODES.LIABILITIES:
          return selectedMetrics.map((metric) => ({
            name: METRIC_GROUPS[viewMode][metric].label,
            data: filterDataByRange(processedData[metric], timeRange),
            color: METRIC_GROUPS[viewMode][metric].color,
            type: 'column',
            yAxis: 0,
            stacking: 'normal',
          }));

        case VIEW_MODES.CURRENT:
          return [
            // Total metrics as lines
            {
              name: METRIC_GROUPS[viewMode].totalCurrentAssets.label,
              data: filterDataByRange(processedData.totalCurrentAssets, timeRange),
              color: METRIC_GROUPS[viewMode].totalCurrentAssets.color,
              type: 'line',
              yAxis: 0,
              marker: { enabled: true },
            },
            {
              name: METRIC_GROUPS[viewMode].totalCurrentLiabilities.label,
              data: filterDataByRange(processedData.totalCurrentLiabilities, timeRange),
              color: METRIC_GROUPS[viewMode].totalCurrentLiabilities.color,
              type: 'line',
              yAxis: 0,
              marker: { enabled: true },
            },
            // Component metrics as stacked columns
            ...selectedMetrics
              .filter(metric => !['totalCurrentAssets', 'totalCurrentLiabilities'].includes(metric))
              .map((metric) => ({
                name: METRIC_GROUPS[viewMode][metric].label,
                data: filterDataByRange(processedData[metric], timeRange),
                color: METRIC_GROUPS[viewMode][metric].color,
                type: 'column',
                stacking: 'normal',
              }))
          ];

        case VIEW_MODES.NON_CURRENT:
          return [
            // Total metrics as lines
            {
              name: METRIC_GROUPS[viewMode].totalNonCurrentAssets.label,
              data: filterDataByRange(processedData.totalNonCurrentAssets, timeRange),
              color: METRIC_GROUPS[viewMode].totalNonCurrentAssets.color,
              type: 'line',
              yAxis: 0,
              marker: { enabled: true },
            },
            {
              name: METRIC_GROUPS[viewMode].totalNonCurrentLiabilities.label,
              data: filterDataByRange(processedData.totalNonCurrentLiabilities, timeRange),
              color: METRIC_GROUPS[viewMode].totalNonCurrentLiabilities.color,
              type: 'line',
              yAxis: 0,
              marker: { enabled: true },
            },
            // Component metrics as stacked columns
            ...selectedMetrics
              .filter(metric => !['totalNonCurrentAssets', 'totalNonCurrentLiabilities'].includes(metric))
              .map((metric) => ({
                name: METRIC_GROUPS[viewMode][metric].label,
                data: filterDataByRange(processedData[metric], timeRange),
                color: METRIC_GROUPS[viewMode][metric].color,
                type: 'column',
                stacking: 'normal',
              }))
          ];

        case VIEW_MODES.SHARES_OUTSTANDING:
          return [
            {
              name: "Split-Adjusted Shares",
              type: 'column',
              yAxis: 0,
              color: METRIC_GROUPS[viewMode].commonStockSharesOutstanding.color,
              data: filterDataByRange(adjustForSplits(sharesData, splits).map(([date, value]) => [date, value * divisor]), timeRange),
            },
            {
              name: "YoY Change",
              type: 'line',
              yAxis: 1,
              color: '#2196F3',
              data: filterDataByRange(calculateYoYChange(adjustForSplits(sharesData, splits), period === 'quarterly'), timeRange),
            }
          ];

        default:
          return [];
      }
    })(),
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function() {
        const date = Highcharts.dateFormat("%Y-%m-%d", this.x);
        let rows = [`<b>${date}</b>`];

        if (viewMode === VIEW_MODES.SHARES_OUTSTANDING) {
          this.points.forEach((point) => {
            if (point.series.name === "YoY Change") {
              // Format as percentage for YoY change
              rows.push(`${point.series.name}: ${point.y === null ? 'N/A' : point.y.toFixed(2) + '%'}`);
            } else {
              // Format as whole numbers for share counts
              rows.push(`${point.series.name}: ${Highcharts.numberFormat(point.y, 0)}`);
            }
          });
        } else {
          this.points.forEach((point) => {
            const value = Math.abs(point.y); // Use absolute value for display
            const sign = point.y < 0 ? '-' : '';
            rows.push(`${point.series.name}: ${sign}$${value.toFixed(2)}${suffix}`);
          });
        }
        return rows.join("<br/>");
      },
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        events: {
          hide: function() {
            const chart = this.chart;
            const allHidden = chart.series.every(s => !s.visible);
            if (allHidden) setAllSeriesVisible(false);
          },
          show: function() {
            const chart = this.chart;
            const allVisible = chart.series.every(s => s.visible);
            if (allVisible) setAllSeriesVisible(true);
          }
        }
      }
    }
  };

  const handleViewModeChange = (newMode) => {
    setViewMode(newMode);
    // No need to set selectedMetrics as it's derived from viewMode
  };

  const handleToggleAll = (event) => {
    const chart = chartRef?.current?.chart;
    if (!chart) return;

    const visible = event.target.checked;
    chart.series.forEach(series => series.setVisible(visible, false));
    chart.redraw();
    setAllSeriesVisible(visible);
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <ChartThemeWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {/* <Typography variant="h6">Balance Sheet</Typography> */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <ButtonGroup size="small">
              <Button
                variant={viewMode === VIEW_MODES.OVERVIEW ? "contained" : "outlined"}
                onClick={() => handleViewModeChange(VIEW_MODES.OVERVIEW)}
              >
                Overview
              </Button>
              <Button
                variant={viewMode === VIEW_MODES.CURRENT ? "contained" : "outlined"}
                onClick={() => handleViewModeChange(VIEW_MODES.CURRENT)}
              >
                Current
              </Button>
              <Button
                variant={viewMode === VIEW_MODES.NON_CURRENT ? "contained" : "outlined"}
                onClick={() => handleViewModeChange(VIEW_MODES.NON_CURRENT)}
              >
                Non-Current
              </Button>
              <Button
                variant={viewMode === VIEW_MODES.SHARES_OUTSTANDING ? "contained" : "outlined"}
                onClick={() => handleViewModeChange(VIEW_MODES.SHARES_OUTSTANDING)}
              >
                Shares Outstanding
              </Button>
            </ButtonGroup>

            <ButtonGroup size="small">
              <Button
                variant={period === "quarterly" ? "contained" : "outlined"}
                onClick={() => setPeriod("quarterly")}
              >
                Quarterly
              </Button>
              <Button
                variant={period === "annual" ? "contained" : "outlined"}
                onClick={() => setPeriod("annual")}
              >
                Annual
              </Button>
            </ButtonGroup>

            <ButtonGroup size="small">
              <Button
                variant={timeRange === "5Y" ? "contained" : "outlined"}
                onClick={() => setTimeRange("5Y")}
              >
                5Y
              </Button>
              <Button
                variant={timeRange === "ALL" ? "contained" : "outlined"}
                onClick={() => setTimeRange("ALL")}
              >
                ALL
              </Button>
            </ButtonGroup>
          </Box>
        </Box>

        <Box sx={{ height: "400px" }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        </Box>
        <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={allSeriesVisible}
                  onChange={handleToggleAll}
                />
              }
              label="Show/Hide All"
            />

      </ChartThemeWrapper>
    </Paper>
  );
};

export default BalanceSheetChart;
