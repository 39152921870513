export const formatLargeValue = (value) => {
  if (value > 1000000000000) {
    // 1 Trillion
    return Math.round((value / 1000000000000) * 100) / 100 + " T";
  } else if (value > 1000000000) {
    return Math.round((value / 1000000000) * 100) / 100 + " B";
  } else if (value > 1000000) {
    return Math.round((value / 1000000) * 100) / 100 + " M";
  }
  return value;
};

export const formatPercentage = (value) => {
  return Math.round(value * 10000) / 100 + "%";
};

export const formatPercentageToText = (value) => {
  if (!value || isNaN(value)) return "N/A";
  return `${(value * 100).toFixed(2)}%`;
};
