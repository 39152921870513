// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebaseConfig } from "./constants/firebaseConst";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore();
export const auth = getAuth(app);

export const logout = (navigate) => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      navigate("/login");
    })
    .catch((error) => {
      // An error happened.
    });
};

export const signIn = (email, password, navigate, setOpen, setAlertMessage) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
      navigate("/dashboard");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setAlertMessage(errorMessage);
      setOpen(true);
    });
};

export const signUp = (email, password, navigate, setOpen, setAlertMessage) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
      navigate("/login");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setAlertMessage(errorMessage);
      setOpen(true);
      // ..
    });
};

export default db;
