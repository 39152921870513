import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Paper, Typography, Box, ButtonGroup, Button } from "@mui/material";
import ChartThemeWrapper from "./ChartThemeWrapper";

const PriceChart = ({ stockData }) => {
  const [timeframe, setTimeframe] = useState("5Y");
  const [chartData, setChartData] = useState({ prices: [], volumes: [] });

  // Process data when component mounts or when timeframe/stockData changes
  useEffect(() => {
    if (!stockData?.time_series_monthly_adjusted?.["Monthly Adjusted Time Series"]) {
      return;
    }

    const processData = () => {
      const filteredData = filterDataByTimeframe(
        stockData.time_series_monthly_adjusted["Monthly Adjusted Time Series"],
        timeframe
      ).sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

      const prices = filteredData.map(([date, values]) => [
        new Date(date).getTime(),
        parseFloat(values["5. adjusted close"]) || 0,
      ]);

      const volumes = filteredData.map(([date, values]) => [
        new Date(date).getTime(),
        parseInt(values["6. volume"]) || 0,
      ]);
      setChartData({ prices, volumes });
    };

    processData();
  }, [timeframe, stockData]);

  const filterDataByTimeframe = (data, period) => {
    if (period === "ALL") return Object.entries(data);

    const now = new Date();
    const periodInMonths = {
      "1M": 1,
      "3M": 3,
      "6M": 6,
      "1Y": 12,
      "3Y": 36,
      "5Y": 60,
    };

    const cutoffDate = new Date(
      now.setMonth(now.getMonth() - periodInMonths[period])
    );
    return Object.entries(data).filter(
      ([date]) => new Date(date) >= cutoffDate
    );
  };

  const options = {
    title: { text: "Historical Stock Price & Volume" },
    chart: {
      style: { fontFamily: "Roboto, Arial, sans-serif" },
      height: "400px",
      zoomType: "xy",
    },
    xAxis: {
      type: "datetime",
      title: { text: "Date" },
      dateTimeLabelFormats: { day: "%Y-%m-%d" },
    },
    yAxis: [
      {
        title: { text: "Price ($)", style: { color: "#D32F2F" } },
        labels: { style: { color: "#D32F2F" } },
        opposite: false,
      },
      {
        title: { text: "Volume", style: { color: "#1976D2" } },
        labels: { style: { color: "#1976D2" } },
        opposite: true,
      },
    ],
    series: [
      {
        name: "Stock Price",
        type: "line",
        data: chartData.prices,
        color: "#D32F2F",
        tooltip: {
          valueDecimals: 2,
          valuePrefix: "$",
        },
        yAxis: 0,
      },
      {
        name: "Volume",
        type: "column",
        data: chartData.volumes,
        color: "#1976D2",
        opacity: 0.5,
        tooltip: {
          valueDecimals: 0,
          valuePrefix: "",
        },
        yAxis: 1,
      },
    ],
    plotOptions: {
      line: { marker: { enabled: false } },
      column: { grouping: false },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      xDateFormat: "%Y-%m-%d",
    },
    credits: { enabled: false },
  };

  if (!stockData?.time_series_monthly_adjusted?.["Monthly Adjusted Time Series"]) {
    return null;
  }

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <ChartThemeWrapper>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}>
          <Typography variant="h6">Price & Volume</Typography>
          <ButtonGroup size="small" aria-label="time period selector">
            {["1M", "3M", "6M", "1Y", "3Y", "5Y", "ALL"].map((period) => (
              <Button
                key={period}
                onClick={() => setTimeframe(period)}
                variant={timeframe === period ? "contained" : "outlined"}
              >
                {period}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box sx={{ height: "400px" }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
      </ChartThemeWrapper>
    </Paper>
  );
};

export default PriceChart;
